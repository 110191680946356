import {watchEffect} from "vue";
import Web3 from "web3";
import {apiGetChainNamespace} from "caip-api";
import UniversalProvider from "@walletconnect/universal-provider";
import {Web3Modal} from "@web3modal/standalone";

export default {
    data() {
        return {
            web3Modal:null,
            provider:null,
            client:null,
            chains:null,
            web3Provider:null,
            session :null,
        }
    },
    created() {
        this.getEip155ChianNamespace()
        this.createClient()
    },
    mounted() {
        watchEffect(() => {
            if (this.provider&& this.web3Modal) {
                this.subscribeToProviderEvents(this.provider)
            }
        })
    },
    methods:{
        createWeb3Provider(_provider){
            this.web3Provider = new Web3(_provider)
        },
        async subscribeToProviderEvents  (_client)  {
            if (typeof _client === "undefined") {
                throw new Error("WalletConnect is not initialized")
            }

            _client.on("display_uri", async (uri) => {
                console.log("EVENT", "QR Code Modal open")
                this.web3Modal?.openModal({ uri })
            })

            // Subscribe to session ping
            _client.on("session_ping", (id, topic) => {
                console.log("EVENT", "session_ping")
                console.log(id, topic)
            })

            // Subscribe to session event
            _client.on(
                "session_event",
                (event, chainId ) => {
                    console.log("EVENT", "session_event")
                    console.log(event, chainId)
                }
            )

            // Subscribe to session update
            _client.on(
                "session_update",
                (
                    topic,
                    _session
                ) => {
                    console.log("EVENT", "session_updated")
                    console.log("TOPIC", topic)
                    this.session = _session
                }
            )

            // Subscribe to session delete
            _client.on(
                "session_delete",
                (id, topic ) => {
                    console.log("EVENT", "session_deleted")
                    console.log(id, topic)
                    this.session = undefined
                    this.account = undefined
                }
            )
        },
        async getEip155ChianNamespace (){
            const namespace = "eip155"
            let _chains= null
            try {
                _chains = await apiGetChainNamespace(namespace)
            } catch (e) {
                // ignore error
            }
            if (typeof _chains !== "undefined") {
                this.chains = _chains
            }
        },
        async createClient(){
            if (!this.client) {
                const _provider = await UniversalProvider.init({
                    projectId: 'b0d1678a1deb7707e0d9fb843b700152',
                    relayUrl: "wss://relay.walletconnect.com",
                })

                const _web3Modal = new Web3Modal({
                    projectId: 'b0d1678a1deb7707e0d9fb843b700152',
                    walletConnectVersion: 2,
                })
                this.provider =_provider

                // @ts-ignore
                this.client = _provider.client

                this.web3Modal = _web3Modal

            }
        },
    }
}
