<template>

  <div class="announcement">
    <div class="top">
      <img src="../assets/back@2x.png" alt="" class="sidebar" @click="$router.go(-1)"/>
      <span>{{$t($route.meta.title)}}</span>
      <span> </span>

    </div>
    <van-list
        v-model="loading"
        :finished="finished"
        :finished-text="$t('home.noMore')"
        :loading-text="$t('home.loading')"
        @load="onLoad"
    >
       <div v-for="(item,i) in list" :key="i" class="listInfo" @click="$router.push({
       path:'/announcementDetails',
       query:{
         id:item.id
       }
       })">
         <p>{{item.releaseTime}} </p>
         <div class="content">
           <p>{{item.title}}</p>
           <span>{{item.content}}</span>
         </div>
       </div>
    </van-list>
  </div>

</template>

<script>
import {getNoticeList} from "@/api";
import {toHHmmss} from "@/utils/decimalTool";

export default {
  name: "announcement",
  data() {
    return {
      loading: false,
      finished: false,
      list:[],
      total:0,
      pageNo:1
    }
  },
  created() {
    this.httpInit()
  },
  methods: {
    httpInit(){
      getNoticeList({
        "pageNo": this.pageNo,
        "pageSize": 10,
        "data": {}
      }).then(res=>{
        this.total=res.data.total
        res.data.list.forEach(v=>{
          v.releaseTime=toHHmmss(v.releaseTime,+8,1)
          if(v.title.length>20){
            v.title=v.title.substring(0,20)+'...'
          }
          if(v.content.length>40){
            v.content=v.content.substring(0,40)+'...'
          }
          this.list.push(v)
        })
      })
    },
    onLoad() {

      setTimeout(() => {
        // 数据全部加载完成
        if (this.list.length >= this.total) {
          this.finished = true
        } else {
          this.pageNo += 1
          this.httpInit()
        }

        // 加载状态结束
        this.loading = false
      }, 1000)
    },
  }
}
</script>

<style scoped lang="less">
.announcement{
  padding: 18px;
  padding-top: 40px;
  .top{
    position: fixed;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 18px 20px 22px 20px;
    width: 100%;
    margin:0 -18px;
    box-sizing: border-box;
    .sidebar{
      width: 16px;
      height: 11px;
      padding-right:30px;
    }
    span{
      font-size: 18px;
      font-weight: 500;
      color: #FFFFFF;
      min-width: 45px;
    }
    .logo{
      width: 21px;
      height: 18px;
    }
    .record{
      font-size: 16px;
      font-weight: 500;
      color: #2DDCF6;
    }
  }

}
  .listInfo{
    margin-bottom: 37px;
      p{
        font-size: 12px;
        font-weight: 500;
        color: #81B4D1;
        opacity: 0.8;
      }
    .content{
      width: 100%;
      background: #263E92;
      border-radius: 5px;
      padding: 25px 18px;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      p{
        font-size: 17px;
        font-weight: bold;
        color: #2DDCF6;
        margin-top: 0;
        margin-bottom: 15px;
        text-align: left;
      }
      span{
        font-size: 14px;
        font-weight: 500;
        color: #FFFFFF;
        text-align: left;

      }
    }
  }
</style>
