<template>
  <div class="invitation">
    <Top></Top>
    <van-pull-refresh v-model="isLoading" @refresh="onRefresh" pulling-text=" " loosing-text=" " loading-text=" " success-text=" " >
      <div class="invitationTop">
        <p class="yourCode">{{$t('top.yourCode')}}</p>
        <div class="code">
          {{data.inviteCode}}
          <img src="../assets/copy.png" alt="" :data-clipboard-text="data.inviteCode" class="copy" @click="copyUrl">
        </div>
        <div class="nameplate" v-if="data.serviceProviderLevel>1 ||data.miningPoolLevel>1">
          <span v-if="data.serviceProviderLevel>1">{{data.serviceProviderLevel==2?$t('top.cxs1'):data.serviceProviderLevel==3?$t('top.cxs2'):data.serviceProviderLevel==4?$t('top.cxs3'):data.serviceProviderLevel==5?$t('top.cxs4'):''}}</span>
          <span v-if="data.miningPoolLevel>1">{{data.miningPoolLevel==2?$t('top.kc1'):data.miningPoolLevel==3?$t('top.kc2'):data.miningPoolLevel==4?$t('top.kc3'):''}}</span>
        </div>
        <div id="qrcode" ref="qrCodeUrl" class="idCard"></div>
        <div class="copyLink copy" @click="copyUrl" :data-clipboard-text="data.url+'?inviteCode='+data.inviteCode">{{$t('top.fz')}}</div>
      </div>
      <div class="invitationBottom">
        <div style="display: flex">
          <div class="title">{{$t('top.ztyh')}}</div>

        </div>
          <div v-for="(item, i) in data.straightUsers" :key="i" >
            <div class="list flex-between-center">
              {{item.address}}
              <span> {{item.level}}</span>
            </div>
          </div>
        <div class="noMore" v-if=" data.straightUsers &&data.straightUsers==0 ">{{$t('home.noMore')}}</div>
<!--        <van-list-->
<!--            v-model="loading"-->
<!--            :finished="finished"-->
<!--            :finished-text="$t('home.noMore')"-->
<!--            :loading-text="$t('home.loading')"-->
<!--            @load="onLoad"-->
<!--        >-->
<!--          <van-cell v-for="(item, i) in 2" :key="i">-->
<!--            -->
<!--          </van-cell>-->
<!--        </van-list>-->
      </div>
    </van-pull-refresh>


  </div>

</template>

<script>
import Top from './components/Top'
import QRCode from 'qrcodejs2'
import Clipboard from 'clipboard'
import {Toast} from "vant";
import {getInviteInfo} from "@/api";

export default {
  name: "Invitation",
  components:{Top},

  data() {
    return {
      isLoading:false,
      loading: false,
      finished: false,
      pageNo:1,
      list:[],
      total:0,
      data:{}
    }
  },

  mounted() {
      this.httpInit()

  },
  methods: {
    onLoad() {
      setTimeout(() => {
        // 数据全部加载完成
        if (this.list.length >= this.total) {
          this.finished = true
        } else {
          this.pageNo += 1
          this.$nextTick(()=>{
            this.httpInit()

          })
        }

        // 加载状态结束
        this.loading = false
      }, 1000)
    },
    httpInit(){
      getInviteInfo().then(res=>{
        res.data.straightUsers.forEach(v=>{
         v.address= v.address.substring(0, 9) +
          '****' +
          v.address.substr(v.address.length - 12)
        })
        this.data=res.data
        document.getElementById('qrcode').innerHTML = ''
        // let widths = document.documentElement.clientWidth * 0.41
        // console.log(this.data.straightUsers,'data.straightUsers')
        new QRCode(this.$refs.qrCodeUrl, {
          text: res.data.url+'?inviteCode='+res.data.inviteCode, // 需要转换为二维码的内容
          width: 166,
          height: 166,
          colorDark: '#000',
          colorLight: '#fff',
          correctLevel: QRCode.CorrectLevel.H,
        })
      })
    },
    onRefresh() {
      setTimeout(() => {
        Toast(this.$t('top.success'));
        // 判断是否登录
        if(sessionStorage.getItem('token')){
          this.httpInit()
        }else{
         this.$router.push('/')
        }
        this.isLoading = false;
        this.finished=false
      }, 1000);
    },

    copyUrl() {
      var clipboard = new Clipboard('.copy')
      clipboard.on('success', (e) => {
        this.$toast(this.$t('top.success'))
        //  释放内存
        clipboard.destroy()
      })
    },
  }
}
</script>

<style scoped lang="less">
.nameplate{
  border: 2px solid transparent;
  border-radius: 31px;
  background-clip: padding-box, border-box;
  background-origin: padding-box, border-box;
  background-image: linear-gradient(90deg, #3989FF 0%, #7E24F9 100%), linear-gradient(0deg, #C72FF8, #41CCF2);
  padding: 10px 0 10px 20px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  font-size: 15px;
  font-weight: normal;
  color: #FFFFFF;
  //min-width: 60%;
  margin-bottom: 20px;
  span{
    margin-right: 20px;
  }
}
.noMore{
  font-size: 14px;
  font-weight: 500;
  color: #FFFFFF;
  text-align: center;
  margin-top: 30px;
}
.invitation{
  background-image: url("../assets/invcode_bg@2x.png");
  background-repeat: no-repeat;
  background-size: 100% 47%;
  min-height: 100vh;
  width: 100%;
  margin-top: 51px;
}
.invitationTop{
  display: flex;
  align-items: center;
  flex-direction: column;

  .yourCode{
    font-size: 14px;
    font-weight: 500;
    color: #FEFEFE;
    //margin-top: 77px;
    margin-bottom: 17px;
  }
  .code{
    font-size: 27px;
    font-weight: bold;
    color: #FEFEFE;
    margin-bottom: 15px;
    img{
      width: 14px;
      height: 15px;
    }
  }
  .idCard{
    background: #FFFFFF;
    padding: 10px;
    margin-bottom: 20px;
  }
  .copyLink{
    background: linear-gradient(90deg, #C72FF8 0%, #41CCF2 100%);
    border-radius: 18px;
    font-size: 15px;
    font-weight: bold;
    color: #FFFFFF;
    padding: 10px 30px ;
  }
}
.invitationBottom{
  width: 100%;
  min-height: 70vh;
  background: linear-gradient(-90deg, #6A2DD7, #1C7FD6, #224EAB);
  border-radius: 21px 21px 0px 0px;
  margin-top: 20px;
  padding: 21px;
  box-sizing: border-box;
  .title{
    font-size: 16px;
    font-weight: bold;
    font-style: italic;
    color: #0A1F4A;
    background-image: url("../assets/title_pic@2x.png");
    background-size: 100% 100%;
    justify-content: center;
    padding: 10px 41px 10px 20px;
    display: inline-block;
    text-align: left;
    box-sizing: border-box;
  }
  /deep/.van-cell{
    background: transparent;
    padding: 0;
  }
  /deep/.van-cell::after{
    display: none;
  }
  .list{
    border-bottom: 1px solid #196CF3;
    padding: 20px 0 15px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    font-weight: 500;
    color: #FFFFFF;
    span{
      font-size: 14px;
      font-weight: 500;
      color: #2DDCF6;
    }
  }
}
</style>
