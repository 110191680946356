import  { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/home.vue'
import Maintenance from '../views/maintenance.vue'
import Invitation from '../views/invitation.vue'
import Assets from '../views/assets.vue'
import AssetDetails from '../views/assetDetails.vue'
import Transaction from '../views/transaction.vue'
import Records from '../views/records.vue'
import NFTTabulation from '../views/NFTTabulation.vue'
import NFTDetails from '../views/NFTDetails.vue'
import Announcement from '../views/announcement.vue'
import AnnouncementDetails from '../views/announcementDetails.vue'


const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  }, {
    path: '/invitation',
    name: 'Invitation',
    meta:{
      title:'router.invitation'
    },
    component: Invitation
  },{
    path: '/maintenance',
    name: 'Maintenance',
    component: Maintenance
  },{
    path: '/assets',
    name: 'Assets',
    meta:{
      title:'router.assets'
    },
    component: Assets
  },{
    path: '/assetDetails',
    name: 'AssetDetails',

    component: AssetDetails
  },{
    path: '/transaction',
    name: 'Transaction',

    component: Transaction
  },{
    path: '/records',
    name: 'Records',
    meta:{
      title:'router.records'
    },
    component: Records
  },{
    path: '/NFTTabulation',
    name: 'NFTTabulation',
    meta:{
      title:'router.NFTTabulation'
    },
    component: NFTTabulation
  }, {
    path: '/NFTDetails',
    name: 'NFTDetails',
    meta:{
      title:'router.NFTDetails'
    },
    component: NFTDetails
  }, {
    path: '/announcement',
    name: 'Announcement',
    meta:{
      title:'router.announcement'
    },
    component: Announcement
  },{
    path: '/announcementDetails',
    name: 'AnnouncementDetails',
    meta:{
      title:'router.announcementDetails'
    },
    component: AnnouncementDetails
  },

]


const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})


router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  // if (to.meta.title) {
  //   document.title = to.meta.title
  // }
  // chrome
  document.body.scrollTop = 0
  // firefox
  document.documentElement.scrollTop = 0
  // safari
  window.pageYOffset = 0
  next()
})

export default router
