<template>
  <div class="NFTTabulation">
    <Top></Top>
    <div class="input">
      <van-field
          v-model="tokenId"
          :right-icon="require('../assets/search@2x.png')"
          :placeholder="$t('nft.tokenIdP')"
          @click-right-icon="changeStatus"
          @blur="changeStatus"
          clearable
      />
    </div>

    <div class="content">
      <div class="search">
        <van-dropdown-menu>
          <van-dropdown-item v-model="status" :options="option1" @change="changeStatus" />
        </van-dropdown-menu>
<!--        <van-checkbox v-model="checked" @change="changeChecked">{{$t('nft.qx')}}</van-checkbox>-->
      </div>
      <van-list
          v-model="loading"
          :finished="finished"
          :finished-text="$t('home.noMore')"
          :loading-text="$t('home.loading')"
          @load="onLoad"
      >
        <div class="list">
          <div v-for="(item,i) in list" class="listInfo" @click="goDetails(item)">
<!--            <van-checkbox v-model="item.checked"></van-checkbox>-->
<!--            <div v-if="item.status==1 ||item.status==2">-->
<!--              <img src="../assets/card1.png" alt="" v-if="item.checked"/>-->
<!--              <img src="../assets/card1@2x.png" alt="" v-else/>-->
<!--            </div>-->
                <img src="../assets/wzy.png" alt="" v-if="item.status==1 " />
              <img :src="item.cardUrl" alt="" v-else/>
              <div class="num" v-if="noPledgeCount!=0 && item.status==1">
                <div >{{item.tokenId}}</div>
              </div>
            <div class="num" v-if="item.status!=1">
              <div>{{'#'+item.tokenId+'#'}}</div>
            </div>
              <p>{{getStatus(item.status)}}</p>

          </div>

        </div>
      </van-list>
    </div>
<!--    <div class="bottom">-->
<!--      <van-button class="gradient" @click="transfer">{{$t('nft.zc')}}</van-button>-->
<!--      <van-button class="blue" @click="storage">{{$t('nft.zy')}}</van-button>-->
<!--    </div>-->
    <!--    转出-->
<!--    <van-popup v-model="showTransfer" round position="bottom" closeable class="popup">-->
<!--      <div class="transferShowTitle">{{$t('assetDetails.transfer')}}</div>-->
<!--      <div class="transferContent">-->
<!--        {{$t('assetDetails.num')}}：-->
<!--        <span>{{balance}}</span>-->
<!--      </div>-->
<!--      <div class="transferContent">-->
<!--        {{$t('assetDetails.balance')}}：-->
<!--        <van-stepper v-model="value" theme="round" button-size="22" disable-input class="stepper" :disable-plus="value>=balance"/>-->
<!--      </div>-->
<!--      <div class="transferContent">-->
<!--        {{$t('assetDetails.address')}}：-->
<!--        <van-field v-model="address" :placeholder="$t('assetDetails.placeholder')"   rows="1"  type="textarea" autosize />-->
<!--        &lt;!&ndash;        <span>0x1029dadn1d19bd91d0b1db0d1b9d1</span>&ndash;&gt;-->
<!--      </div>-->
<!--      <van-button class="gradient" @click="transferOut">{{$t('assetDetails.transferOut')}}</van-button>-->
<!--    </van-popup>-->

    <!--   质押-->
<!--    <van-popup v-model="reservationShow"   class="popupReservation">-->
<!--      <div class="popupTitle">-->
<!--        <div style="width: 24px;height: 15px"></div>-->
<!--        <div class="title flex">-->
<!--          {{$t('nft.zy')}}-->
<!--        </div>-->
<!--        <img src="../assets/close@2x.png" alt="" @click="reservationShow=false"/>-->
<!--      </div>-->
<!--      <div class="stepper">-->
<!--        <van-stepper v-model="pledgeNum" theme="round" button-size="27" disable-input />-->
<!--      </div>-->

<!--      <van-button class="recharge" @click="submit">{{$t('nft.zy')}}</van-button>-->
<!--    </van-popup>-->
  </div>

</template>

<script>
import Top from './components/Top'
import {getBalance, getNftCardList, getWalletDetail, pledge, transOut} from '@/api'
export default {
  name: "NFTTabulation",
  components:{Top},
  data() {
    return {
      noPledgeCount:'',
      tokenId:null,
      status:'',
      option1:[
          { text: this.$t('nft.qb'), value: '' },
          { text: this.$t('nft.wzy'), value: 1 },
          { text: this.$t('nft.zyz'), value: 2 },
          // { text: this.$t('nft.tcz'), value: 3 },
          // { text: this.$t('nft.ytc'), value: 4 },
          { text: this.$t('nft.yfq'), value: 5 },
          { text: this.$t('nft.qyjs'), value: 6 },
      ],
      // checked:false,
      loading: false,
      finished: false,
      list:[
          {status:1}
          ],
      total:0,
      pageNo:1,
      address:'',
      value:0,
      balance:null,
      showTransfer:false,
      pledgeNum:1,
      reservationShow:false,
    }
  },
  created() {
    this.httpInit()

  },
  methods: {
    goDetails(item){
      if(this.noPledgeCount==0 && item.status==1){
        this.$toast(this.$t('nft.zyP'))

      }else{
        this.$router.push({
          path:'/NFTDetails',
          query:{
            id:item.id,
            status:item.status
          }
        })
      }


    },
    getStatus(status){
      let text
      switch (status){
        case 1 :
          text=this.$t('nft.wzy')
          break
        case 2 :
          text=this.$t('nft.zyz')
          break
        case 5 :
          text=this.$t('nft.yfq')
          break
        case 6 :
          text=this.$t('nft.qyjs')
          break
      }
      return text
    },
    submit(){
      pledge({
        "pageNo": 0,
        "pageSize": 0,
        "data": this.pledgeNum
      }).then(()=>{
        this.reservationShow=false
        this.$toast(this.$t('top.success'))
        this.list=[{status:1}]
        this.pageNo=1
        this.httpInit()
      })
    },
    //质押
    storage(){
      this.pledgeNum=1
      this.reservationShow=true
    },
    transfer(){
      getBalance().then(res=>{
        this.balance=res.data
        this.value=0
        this.address=null
        this.showTransfer=true
        this.list=[{status:1}]


        this.pageNo=1
        this.httpInit()
      })
    },
    transferOut(){
      transOut({
        "pageNo": 0,
        "pageSize": 0,
        "data": {
          "count": this.value, //转出数量
          "address": this.address //转入数量
        }
      }).then(res=>{
        this.showTransfer=false
        this.$toast(this.$t('top.success'))
        this.list=[{status:1}]



        this.pageNo=1
        this.httpInit()
      })
    },
    // changeChecked(){
    //   if(this.checked){
    //     this.list.forEach(v=>{
    //       v.checked=true
    //     })
    //   }else{
    //     this.list.forEach(v=>{
    //       v.checked=false
    //     })
    //   }
    //
    // },
    changeStatus(){
      this.pageNo=1
      if(this.status=='' || this.status==1  ){
        if(this.tokenId===''){
          this.list=[{status:1}]
          this.httpInit()
        }else{
          this.list=[]
          this.httpInit()
        }
      }else{
        this.list=[]
        this.httpInit()
      }
      // if(this.tokenId==='' && (this.status=='' || this.status==1  )){
      //   this.list=[{status:1}]
      //
      //   this.httpInit()
      //
      // }else{
      //   this.list=[]
      //   this.httpInit()
      //
      // }
    },
    httpInit(){
      getNftCardList(
          {
            "pageNo": this.pageNo,
            "pageSize": 10,
            "data": {
              "tokenId":this.tokenId, //TOKEN ID
              "status": this.status, //状态 1.未质押 2.质押中 3.提出中 4.已提出 5.已废弃 6.权益结束
            }
          }
      ).then(res=>{
          // res.data.list.forEach(v=>{
          //   v.checked=false
          //   this.list.push(v)
          // })
        this.noPledgeCount=res.data.noPledgeCount
        this.list.push(...res.data.list)
        this.total=res.data.total
        this.list.forEach(v=>{
          if(v.status==1 ){
            v.tokenId='x'+  this.noPledgeCount
          }
        })
      })
    },
    onLoad() {

      setTimeout(() => {
        // 数据全部加载完成
        if (this.list.length-1 >= this.total) {
          this.finished = true
        } else {
          this.pageNo += 1
          this.httpInit()
          // this.getList(this.active)
        }

        // 加载状态结束
        this.loading = false
      }, 1000)
    },
  }
}
</script>

<style scoped lang="less">
.NFTTabulation{
  background: url("../assets/nft_pic@2x.png") no-repeat;
  background-size: 100% ;
  min-height: 100vh;
  width: 100%;
  box-sizing: border-box;
  padding-top: 83px;
  .input{
    margin-bottom: 34px;
    /deep/.van-cell{
      width: 80%;
      height: 46px;
      margin: 0 auto;
      border: 0.2px solid #FFFFFF;
      background: linear-gradient(90deg, #4E7CEB 0%, #00E3DA 100%);
      border-radius: 23px;
    }
    /deep/.van-icon__image{
      width: 18px !important;
      height: 18px !important;
      //margin-top: 3px;
      //margin-right: 13px;
    }
    /deep/.van-field__control{
      font-size: 15px;
      font-weight: 500;
      color: #FFFFFF;
    }
    /deep/.van-field__control::placeholder{
      font-size: 15px;
      font-weight: 500;
      color: #FFFFFF;
    }
  }

  .content{
    min-height:80vh ;
    background: #122077;
    border-radius: 31px 31px 0px 0px;
    padding: 28px 20px ;
    .search{
      display: flex;
      align-items: center;
      justify-content: space-between;
      /deep/.van-dropdown-menu__bar{
        background: transparent;
        box-shadow: none;
      }
      /deep/.van-ellipsis{
        font-size: 15px;
        font-weight: 500;
        color: #FFFFFF;
      }
      /deep/.van-checkbox__label{
        font-size: 15px;
        font-weight: 500;
        color: #FFFFFF;
      }
      /deep/.van-checkbox__icon{
        .van-icon{
          background: #FFFFFF;
          border: 1px solid #2DDCF6;
        }
      }
      /deep/.van-checkbox__icon--checked{
        .van-icon{
          background: linear-gradient(90deg, #C72FF8, #41CCF2);
          border: none;
        }
      }
    }
  }
}
.van-list{
  padding-bottom: 86px;
}
/deep/.list{
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  .listInfo{
    width: 47%;
    position: relative;
    margin-bottom: 10px;
    img{
      width: 98%;
      height: 197px;
      object-fit:cover; //裁剪图片
      border-radius: 8px;
    }
    .van-checkbox{
      position: absolute;
      right: 5px;
      top:5px
    }
    .van-checkbox__icon{
      .van-icon{
        background: #FFFFFF;
        border: 1px solid #2DDCF6;
      }
    }
    .van-checkbox__icon--checked{
      .van-icon{
        background: linear-gradient(90deg, #C72FF8, #41CCF2);
        border: none;
      }
    }

    .num{
        display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      position: absolute;
      top:68%;
      div{
        background: linear-gradient(90deg, #C72FF8 0%, #41CCF2 100%);
        border-radius: 14px;
        font-size: 16px;
        font-weight: bold;
        color: #FFFFFF;
        padding: 7px 19px;
        display: inline-block;
      }
    }
    p{
      font-size: 15px;
      font-weight: bold;
      color: #F545F7;
      margin-top: 5px;
    }
  }

}
.bottom{
  position: fixed;
  bottom: 0;
  padding: 20px;
  box-sizing: border-box;
  width: 100%;
  background: #122077;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .gradient{
    width: 48%;
    height: 46px;
    background: linear-gradient(90deg, #C72FF8 0%, #41CCF2 100%);
    border-radius: 23px;

    font-size: 17px;
    font-weight: bold;
    color: #FFFFFF;
    border: none;
  }
  .blue{
    width: 48%;
    height: 46px;
    background: #2DDCF6;
    border-radius: 23px;
    border: none;

    font-size: 17px;
    font-weight: bold;
    color: #FFFFFF;
  }
}
.popup{
  background: #224EAB;
  border-radius: 31px 31px 0px 0px;
  padding: 21px 19px ;
  display: flex;
  align-items: center;
  flex-direction: column;
  box-sizing: border-box;
  padding-bottom: 50px;
  .gradient{

    width: 80%;
    height: 46px;
    background: linear-gradient(90deg, #C72FF8 0%, #41CCF2 100%);
    border-radius: 23px;
    border: 0;
    font-size: 17px;
    font-weight: bold;
    color: #FFFFFF;
  }
  .transferShowTitle{
    width: 175px;
    background-image: url("../assets/title_pic@2x.png");
    background-size: 100% 100%;
    padding: 4px;
    font-size: 16px;
    font-weight: bold;
    font-style: italic;
    color: #0A1F4A;
    box-sizing: border-box;
    margin-bottom: 42px;
  }
  .transferContent{
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 16px;
    font-weight: 500;
    color: #FFFFFF;
    width: 100%;
    margin-bottom: 34px;
    //span{
    //  width: 211px;
    //  font-size: 16px;
    //  font-weight: 500;
    //  color: #FFFFFF;
    //  text-align: right;
    //  word-break: break-all;
    //}
    /deep/.van-cell{
      background: transparent;
      flex: 1;
    }

    /deep/.van-field__control{
      font-size: 16px;
      font-weight: 500;
      color: #FFFFFF;
      text-align: right;
      word-break: break-all;
    }
  }
  .stepper{
    width: 132px;
    background: #122077;
    border-radius: 10px;
    padding: 10px 12px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;

   .van-stepper__input{
      flex: 1;
      text-align: center;
      font-size: 18px ;
      font-weight: bold;
      color: #CCDBF1;
    }
    .van-stepper__plus{
      background: #2DDCF6;
      color: #122077;
      font-weight: bold;
    }
    .van-stepper__minus{
      background: #2DDCF6;
      color: #122077;
      font-weight: bold;
      border: none;
    }
  }
}
.popupReservation{
  width:75%;
  border-radius: 7px;
  background-color: #224EAB;
  background-image: url("../assets/soccer@2x.png");
  background-repeat: no-repeat;
  background-position: bottom right;
  background-size: 142px 183px;
  padding: 19px 15px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  .popupTitle{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    img{
      width: 15px;
      height: 15px;
    }
    .title{
      font-size: 16px;
      font-weight: bold;
      font-style: italic;
      color: #0A1F4A;
      background-image: url("../assets/title_pic@2x.png");
      background-size: 100% 100%;
      min-width: 150px;
      padding: 8px;
      justify-content: center;
    }

  }
  .recharge{
    width: 75%;
    height: 46px;
    background: linear-gradient(90deg, #C72FF8 0%, #41CCF2 100%);
    border-radius: 23px;
    border: 0;
    font-size: 17px;
    font-weight: 500;
    color: #FFFFFF;
    margin-top: 29px;
  }
  .stepper{
    background: #122077;
    border-radius: 10px;
    padding: 18px 22px ;
    width: 65%;
    margin-top: 29px;
    margin-bottom: 2px;
    .van-stepper--round{
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .van-stepper__input{
      font-size: 27px;
      font-weight: bold;
      color: #CCDBF1;
      flex: 1;
    }
    .van-stepper__plus{
      background: #2DDCF6;
      color: #122077;
      font-weight: bold;
    }
    .van-stepper__minus{
      background: #2DDCF6;
      color: #122077;
      font-weight: bold;
      border: none;
    }
  }
}

</style>
