<template>
  <div class="assetDetails">
    <Top ></Top>
    <van-pull-refresh v-model="isLoading" @refresh="onRefresh" pulling-text="" loosing-text="loading" loading-text="loading" success-text="success" >
      <div class="assets">
        <div class="other">
          <img src="../assets/usdt@2x.png" alt="" v-if="$route.query.currencyName==='USDT'"/>
          <img src="../assets/stt@2x.png" alt="" v-if="$route.query.currencyName==='STT'"/>
          <img src="../assets/nft@2x.png" alt="" v-if="$route.query.currencyName==='NFT'"/>
          <div>
            <strong>{{data.balance}}</strong>
            <span>≈${{data.balanceTwo}}</span>
          </div>
        </div>

        <div class="pledge"  v-if="$route.query.currencyName==='NFT'">
          <span>{{$t('nft.wzy')}}：{{nftData.noPledge}}</span>
          <span>{{$t('nft.zyz')}}：{{nftData.inPledge}}</span>
          <span>{{$t('nft.yfq')}}：{{nftData.hasDiscard}}</span>
        </div>
      </div>
      <div class="details">
        <van-tabs v-model:active="active" color="#2DDCF6" line-width="73" line-height="1" title-inactive-color="#81B4D1" title-active-color="#2DDCF6" @change="changeTab">
          <van-tab :title="item.description" v-for="(item,i) in data.walletLogTypeList" :key="i" :name="item.type">
            <van-list
                v-model="loading"
                :finished="finished"
                :finished-text="$t('home.noMore')"
                :loading-text="$t('home.loading')"
                @load="onLoad"
            >
              <van-cell v-for="(item, i) in list" :key="i">
                <div class="list flex-between-center">
                  <div class="left">
                    <img src="../assets/zc.png" alt="" v-if="item.status==2"/>
                    <img src="../assets/zr.png" alt="" v-if="item.status==1"/>
                    <div>
                      <strong>{{$route.query.currencyName==='NFT'?(item.status==1?$t('assetDetails.zr'):$t('assetDetails.zc')):item.logName}}</strong>
                      <span>{{item.createTime}}</span>
                    </div>
                  </div>
                  <div class="right">
                    <strong :style="item.status==2?'color:#2DDCF6':'' ">{{(item.status==1?'+':'')+item.amount}}</strong>
                    <span v-if="$route.query.currencyName==='NFT'">{{item.address}}</span>
                  </div>
                </div>
              </van-cell>
            </van-list>
            <div class="bottom">
              <van-button class="gradient" v-if="$route.query.currencyName==='NFT'" @click="$router.push('/NFTTabulation')">{{$t('nft.cklb')}}</van-button>
              <div class="noNFT" v-if="$route.query.currencyName==='USDT'">
                <van-button class="gradient"  @click="goTransaction(1)"  :disabled="data.rechargeSwitch!=1">{{$t('assetDetails.recharge')}}</van-button>
                <van-button class="blue"  :disabled="data.withSwitch!=1" @click="goTransaction(2)">{{$t('assetDetails.with')}}</van-button>
              </div>
              <van-button class="gradient" v-if="$route.query.currencyName==='STT'" @click="goTransaction(2)">{{$t('assetDetails.with')}}</van-button>

            </div>
          </van-tab>

        </van-tabs>
      </div>
    </van-pull-refresh>

    <!--    转出-->
    <van-popup v-model:show="show" round position="bottom" closeable class="popup">
      <div class="transferShowTitle">{{$t('assetDetails.transfer')}}</div>
      <div class="transferContent">
        {{$t('assetDetails.num')}}：
        <span>{{data.balance}}</span>
      </div>
      <div class="transferContent">
        {{$t('assetDetails.balance')}}：
        <van-stepper v-model="value" theme="round" button-size="22" disable-input class="stepper" :disable-plus="value>=data.balance"/>
      </div>
      <div class="transferContent">
        {{$t('assetDetails.address')}}：
        <van-field v-model="address" :placeholder="$t('assetDetails.placeholder')"   rows="1"  type="textarea" autosize />
<!--        <span>0x1029dadn1d19bd91d0b1db0d1b9d1</span>-->
      </div>
      <van-button class="gradient" @click="transferOut">{{$t('assetDetails.transferOut')}}</van-button>
    </van-popup>
  </div>

</template>

<script>
import Top from './components/Top'
import {getWalletDetail, getWalletLogList, nftStatistics, transOut} from "@/api";
import {toHHmmss} from "@/utils/decimalTool";


export default {
  name: "assetDetails",
  components:{Top},
  data() {
    return {
      address:'',
      value:0,
      show:false,
      isLoading:false,
      active:null,
      loading: false,
      finished: false,
      pageNo:1,
      list:[],
      total:0,
      data:{},
      nftData:{},
    }
  },
  created() {
    this.$route.meta.title=this.$route.query.currencyName
    this.httpInit()
  },
  methods: {
    transferOut(){
      transOut({
        "pageNo": 0,
        "pageSize": 0,
        "data": {
          "count": this.value, //转出数量
          "address": this.address //转入数量
        }
      }).then(res=>{
        this.show=false
          this.$toast(this.$t('top.success'))
        this.list=[]
        this.pageNo=1
        this.finished=false
        this.httpInit()
      })
    },
    goTransaction(type){
      // 1充币 2提币
      this.$router.push({
        path:'/transaction',
        query:{
          assetsName:this.$route.query.currencyName,
          type:type
        }
      })
    },
    changeTab(name){
        this.list=[]
        this.pageNo=1
      this.finished=false

      this.getList(name)
    },
    getList(type){
      getWalletLogList({
        "pageNo": this.pageNo,
        "pageSize": 10,
        "data": {
          "assetsName":this.$route.query.currencyName, //资产名称
          "status": type, //状态
        }
      }).then(res=>{
          this.total=res.data.total
        res.data.list.forEach(v=>{
          v.createTime=toHHmmss(v.createTime*1000,+8)
          this.list.push(v)

        })
      })
    },
    httpInit(){
      getWalletDetail({
        "pageNo": 0,
        "pageSize": 0,
        "data": {
          "assetsName": this.$route.query.currencyName //资产名称
        }
      }).then(res=>{
          this.data=res.data
          this.active=res.data.walletLogTypeList[0].type
           this.getList(res.data.walletLogTypeList[0].type)
      })

      if(this.$route.query.currencyName==='NFT'){
        nftStatistics().then(res=>{
          this.nftData=res.data
        })
      }
    },
    onRefresh() {
      setTimeout(() => {
        this.$toast(this.$t('top.success'))
        this.list=[]
        this.pageNo=1
        this.getList( this.active)
        if(this.$route.query.currencyName==='NFT'){
          nftStatistics().then(res=>{
            this.nftData=res.data
          })
        }
        this.isLoading = false;
        this.finished=false
      }, 1000);
    },
    onLoad() {

      setTimeout(() => {
        console.log(this.list.length , this.total)
        // 数据全部加载完成
        if (this.list.length >= this.total) {
          this.finished = true
        } else {
          this.pageNo += 1
          this.getList(this.active)
        }

        // 加载状态结束
        this.loading = false
      }, 1000)
    },

  }
}
</script>

<style scoped lang="less">
/deep/.van-tabs__line{
  z-index: 0;
}
.assetDetails{
  width: 100%;
  min-height: 91vh;
  background: linear-gradient(-90deg, #6A2DD7, #1C7FD6, #224EAB);
  padding-top: 78px;
  .assets{
    margin: 0 17px 17px;
    display: flex;
    align-items: center;
    flex-direction: column;
    box-sizing: border-box;
    .other{
      display: flex;
      align-items: center;
      width: 100%;

      img{
        width: 55px;
        height: 55px;
        margin-right: 20px;
      }
      div{
        display: flex;
        align-items: flex-end;
      }
      strong{
        font-size: 37px;
        font-weight: bold;
        color: #FFFFFF;
      }
      span{
        font-size: 16px;
        font-weight: 500;
        color: #FFFFFF;
        margin-bottom:8px;
        margin-left: 5px;
      }
    }

    .pledge{
      width: 100%;
      margin-top: 25px;
      display: flex;
      align-items: center;
      justify-content: space-around;
      font-size: 15px;
      font-weight: 500;
      color: #FFFFFF;
    }

  }
  .details{
    width: 390px;
    min-height: 80vh;
    background: #122077;
    border-radius: 31px 31px 0px 0px;
    padding-top: 10px;
    padding-bottom: 88px;
    box-sizing: border-box;
    /deep/.van-tabs__nav{
      background: transparent;
    }
    /deep/.van-cell{
      background: transparent;
      padding:  0;
    }
    /deep/.van-list{
      margin-top: 10px;
    }
    /deep/.van-cell::after{
      display: none;
    }
    .list{
      margin: 0  20px;
      padding: 20px 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid rgba(255,255,255,0.4);
      .left{
        display: flex;
        align-items: center;
        div{
          display: flex;
          flex-direction: column;
        }
        img{
          width: 23px;
          height: 23px;
          margin-right: 12px;
          margin-left: 5px;
        }
        strong{
          font-size: 16px;
          font-weight: bold;
          color: #FFFFFF;
        }
        span{
          font-size: 12px;
          font-weight: 500;
          color: #FFFFFF;
          margin-bottom: -3px;
        }
      }
    .right{
      display: flex;
      flex-direction: column;
      text-align: right;

      strong{
        font-size: 16px;
        font-weight: bold;
        color: #F545F7;
      }
      span{
        font-size: 12px;
        font-weight: 500;
        color: #FFFFFF;
        margin-bottom: -3px;

      }
    }
    }
  }
}
.gradient{

  width: 80%;
  height: 46px;
  background: linear-gradient(90deg, #C72FF8 0%, #41CCF2 100%);
  border-radius: 23px;
  border: 0;
  font-size: 17px;
  font-weight: bold;
  color: #FFFFFF;
}
.bottom{
  position: fixed;
  bottom: 0px;
  background: #122077;
  width: 100%;
  padding-bottom: 40px;

  .blue{

    width: 80%;
    height: 46px;
    background: #2DDCF6;
    border-radius: 23px;
    border: 0;
    font-size: 17px;
    font-weight: bold;
    color: #FFFFFF;
  }
  .noNFT{
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 0 17px;
    .gradient{
      width: 45%;
    }
    .blue{
      width: 45%;
    }
  }
}
/deep/.popup{
  background: #224EAB;
  border-radius: 31px 31px 0px 0px;
  padding: 21px 19px ;
  display: flex;
  align-items: center;
  flex-direction: column;
  box-sizing: border-box;
  padding-bottom: 50px;
  .transferShowTitle{
    width: 175px;
    background-image: url("../assets/title_pic@2x.png");
    background-size: 100% 100%;
    padding: 4px;
    font-size: 16px;
    font-weight: bold;
    font-style: italic;
    color: #0A1F4A;
    box-sizing: border-box;
    margin-bottom: 42px;
  }
  .transferContent{
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 16px;
    font-weight: 500;
    color: #FFFFFF;
    width: 100%;
   margin-bottom: 34px;
    //span{
    //  width: 211px;
    //  font-size: 16px;
    //  font-weight: 500;
    //  color: #FFFFFF;
    //  text-align: right;
    //  word-break: break-all;
    //}
    /deep/.van-cell{
      background: transparent;
      flex: 1;
    }

    /deep/.van-field__control{
      font-size: 16px;
      font-weight: 500;
      color: #FFFFFF;
      text-align: right;
      word-break: break-all;
    }
  }
  .stepper{
    width: 132px;
    background: #122077;
    border-radius: 10px;
    padding: 10px 12px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .van-stepper__input{
      flex: 1;
      text-align: center;
      font-size: 18px ;
      font-weight: bold;
      color: #CCDBF1;
    }
    .van-stepper__plus{
      background: #2DDCF6;
      color: #122077;
      font-weight: bold;
    }
    .van-stepper__minus{
      background: #2DDCF6;
      color: #122077;
      font-weight: bold;
      border: none;
    }
  }
}
</style>
