import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Vant from 'vant';
import 'vant/lib/index.css';
import './utils/rem'
import { createI18n } from 'vue-i18n';
import zh from './lang/zh'
import en from './lang/en'
import sp from './lang/sp'
import tr from './lang/tr'


//调试器

import eruda from 'eruda'
//11
if (process.env.NODE_ENV !== 'production') {
//     console.log('线上')
  eruda.init()
}
// 多语言
const i18n =createI18n({
  allowComposition: true,
  globalInjection: true,
  silentTranslationWarn: true,
  legacy: false,
  locale:sessionStorage.getItem('lange') || 'en',
  messages: {
    //配置多语言 根据自己的项目需求  这里配置了中文和英文
    zh: zh, //中文
    en: en, //英文
    es: sp, //西班牙
    tc: tr, //中繁
  },

})



createApp(App)
    .use(router)
    .use(Vant)
    .use(store)
    .use(i18n)
    .mount('#app')
