import { Decimal } from 'decimal.js'
import { baseApi } from '../../base_config.json'
// 加
export function add(x, y) {
  if (!x) {
    x = 0
  }
  if (!y) {
    y = 0
  }
  const xx = new Decimal(x)
  const yy = new Decimal(y)
  return xx.add(yy).toNumber()
}

// 减
export function sub(x, y) {
  if (!x) {
    x = 0
  }
  if (!y) {
    y = 0
  }
  const xx = new Decimal(x)
  const yy = new Decimal(y)
  return xx.sub(yy).toNumber()
}

// 乘
export function mul(x, y) {
  if (!x) {
    x = 0
  }
  if (!y) {
    y = 0
  }
  const xx = new Decimal(x)
  const yy = new Decimal(y)
  return xx.mul(yy).toNumber()
}

// 除
export function div(x, y) {
  if (!x) {
    x = 0
  }
  if (!y) {
    y = 0
  }
  const xx = new Decimal(x)
  const yy = new Decimal(y)
  return xx.div(yy).toNumber()
}

export function  toHHmmss(timestamp, offset,style) {
  // 需要显示的时区
  let targetTimezone;
  // if(baseApi==='http://192.168.5.213:8085'){
  //   targetTimezone = Number(offset )
  // }else{
  //   targetTimezone = Number(offset - 8)
  // }
  targetTimezone = Number(offset )
  // 先把时间戳对应转行并往utc-0处理  转换为当前时区对应的utc-0的时间戳数值
  let utc0 = timestamp + new Date(timestamp).getTimezoneOffset() * 60000;
  // 按照时区转换到时区对应的时间戳
  let resultTimestamp = utc0 + (3600000 * targetTimezone)

  // 实际用到的时间戳
  var data = new Date(resultTimestamp)
  var year = data.getFullYear()
  var month = data.getMonth() + 1
  month = month < 10 ? '0' + month : month
  var day = data.getDate()
  day = day < 10 ? '0' + day : day
  var hours = data.getHours()
  hours = hours < 0 ? 24 + hours : hours
  var minutes = data.getMinutes()
  minutes = minutes < 0 ? 60 + minutes : minutes
  var seconds = data.getSeconds()
  seconds = seconds < 0 ? 60 + seconds : seconds
  var time
  // style 1 2023.4.20 14:21:20 2 2021.4.24
  if(style===1){
    time=
        year +
        '.' +
        month +
        '.' +
        day +
        ' ' +
        (hours < 10 ? '0' + hours : hours) +
        ':' +
        (minutes < 10 ? '0' + minutes : minutes) +
        ':' +
        (seconds < 10 ? '0' + seconds : seconds)
    return time
  }else  if(style===2){
    time=
        year +
        '.' +
        month +
        '.' +
        day
    return time
  }else{
    time  =
        year +
        '-' +
        month +
        '-' +
        day +
        ' ' +
        (hours < 10 ? '0' + hours : hours) +
        ':' +
        (minutes < 10 ? '0' + minutes : minutes) +
        ':' +
        (seconds < 10 ? '0' + seconds : seconds)
    return time
  }

}

export function getOrderStatus(status){
  let text
  switch(status){
    case 1 :
      text='To be paid'
      break
    case 2 :
      text='Receipt confirmation'
      break
    case 3 :
      text='Collection failed'
      break
    case 4 :
      text='Abnormal payment'
      break
    case 5 :
      text='Successfully returned manually'
      break
    case 6 :
      text='Refund preparation'
      break
    case 7 :
      text='Refunding'
      break
    case 8 :
      text='Refund successful'
      break
    case 9 :
      text='Payment successful'
      break
    case 10 :
      text='Redemption block confirmation'
      break
    case 11 :
      text='Redemption failed'
      break
    case 12 :
      text='Exchange Exception'
      break
    case 13 :
      text='Redemption successful'
      break
    case 14 :
      text='Centralization'
      break
    case 15 :
      text='Successfully collected'
      break
    case 16 :
      text='Collection failed'
      break
    case 17 :
      text='Transfer to handling fee'
      break
    case 18 :
      text='Transfer of handling fee failed'
      break
    case 19 :
      text='Successfully transferred the handling fee'
      break
    case 20 :
      text='Abnormal transfer fee'
      break
    case 21 :
      text='Aggregation anomaly'
      break
  }
  return text
}
