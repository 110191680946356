<template>

  <div class="announcementDetails">
    <div class="top">
      <img src="../assets/back@2x.png" alt="" class="sidebar" @click="$router.go(-1)"/>
      <span>{{$t($route.meta.title)}}</span>
      <span> </span>
    </div>
    <p>{{data.title}}</p>
    <span>{{data.releaseTime}}</span>
    <div>{{data.content}}</div>
  </div>

</template>

<script>
import {getNoticeDetail} from "@/api";
import {toHHmmss} from "@/utils/decimalTool";
export default {
  name: "announcementDetails",
  data() {
    return {
      data:{}
    }
  },
  created() {
    this.httpInit()
  },
  methods: {
    httpInit(){
      getNoticeDetail(this.$route.query.id).then(res=>{
        res.data.releaseTime=toHHmmss(res.data.releaseTime,+8 ,2)
        this.data=res.data
      })
    }
  }
}
</script>

<style scoped lang="less">
.announcementDetails{
  padding: 18px ;
  box-sizing: border-box;
  text-align: left;
  display: flex;
  flex-direction: column;
  padding-top: 40px;
  .top{
    position: fixed;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 18px 20px 22px 20px;
    width: 100%;
    margin:0 -18px;
    box-sizing: border-box;
    .sidebar{
      width: 16px;
      height: 11px;
      padding-right:30px;
    }
    span{
      font-size: 18px;
      font-weight: 500;
      color: #FFFFFF;
      min-width: 45px;
    }
    .logo{
      width: 21px;
      height: 18px;
    }
    .record{
      font-size: 16px;
      font-weight: 500;
      color: #2DDCF6;
    }
  }
  p{
    font-size: 20px;
    font-weight: bold;
    color: #2DDCF6;
    margin-bottom: 16px;
  }
  span{
    font-size: 14px;
    font-weight: 500;
    color: #81B4D1;
  }
  div{
    font-size: 15px;
    font-weight: 500;
    color: #FFFFFF;
    margin: 30px 0;
    word-break: break-all;
  }
}
</style>
