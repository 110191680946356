<template>
  <div class="NFTDetails">
    <Top></Top>
    <div class="picture">
      <img :src="data.cardUrl" alt="" class="card" :style="data.status!=1?'  border: 5px solid #2DDCF6':''"/>
      <div class="num"  v-if="data.status!=1" >{{'#'+data.tokenId+'#'}} </div>
      <img src="../assets/kz@2x.png" alt="" class="base"/>
    </div>
    <div class="publisher">
      <div class="publisherInformation">
        <img src="../assets/fbz.png" alt=""/>
        <div >
          <p>{{$t('nft.fbz')}}</p>
          <span>{{data.creator}}</span>
        </div>
      </div>
      <div class="status">
        <p v-if="data.status"  :style="data.status==1?'color: #2DDCF6;':'color: #F545F7;'">{{getStatus(data.status)}}</p>
        <span  v-if="data.status!=1" >{{$t('nft.zysj')}}:{{data.pledgeTime}}</span>
      </div>
    </div>
    <div class="value">
      <div class="nftValue" v-if="data.status==1">
        <p><span>{{data.count}}</span>{{$t('nft.dq')}}</p>
        <p><span>{{data.totalAmount}}</span>{{$t('nft.jz')}}</p>
      </div>
      <div class="nftValue" v-else>
        <p><span>{{data.price}}</span>{{$t('nft.jz')}}</p>
        <p><span>{{data.totalMining}}</span>{{$t('nft.wk')}}</p>
      </div>
      <div class="progress" v-if="data.status!=1" >
        <div class="progressValue">{{data.acquired}}<span>{{data.leftAmount}}</span></div>
        <van-progress
            :percentage="data.proportion"
            :show-pivot="false"
            :stroke-width="15"
            color="#01EDFB"
            track-color="rgba(203, 228, 229, 0.5)"
        />
        <div class="progressText">{{$t('nft.yhqqy')}}<span>{{$t('nft.syqy')}}</span></div>
      </div>
    </div>
    <div class="title">
      {{$t('nft.qy1')}}
    </div>
    <div class="story" >
         <p>{{$t('nft.qy2')}}</p>
         <img src="../assets/pic1@2x.png" alt="" class="pic1"/>
         <p>{{$t('nft.qy3')}}</p>
    </div>

    <div class="title">
      {{$t('nft.zt')}}
    </div>
    <div class="story" >
      <img src="../assets/pic2@2x.png" alt="" class="pic2"/>
      <p> {{$t('nft.zt1')}}</p>
    </div>

    <div class="title">
      {{$t('nft.ys')}}
    </div>
    <div class="story" >
      <img src="../assets/pic3@2x.png" alt="" class="pic3"/>
      <p> {{$t('nft.ys1')}}</p>
    </div>

    <div class="title">
      {{$t('nft.bj')}}
    </div>
    <div class="story" >
      <img src="../assets/pic4@2x.png" alt="" class="pic4"/>
      <p>{{$t('nft.bj1')}}</p>
    </div>
    <div class=" bottom" v-if="data.status==1" >
      <van-button class="gradient"  @click="transfer">{{$t('nft.zc')}}</van-button>
      <van-button class="blue" @click="storage(1)">{{$t('nft.zy')}}</van-button>
    </div>
    <div class="btn"   v-if="data.status==1">
      <van-button class="gradient" style="width: 100%" @click="storage(2)">{{$t('nft.tcddz')}}</van-button>
    </div>
    <div class="btn" v-if="data.status==2">
      <van-button class="blue" style="width: 100%" @click="sConfirmation(1)">{{$t('nft.qxzy')}}</van-button>
    </div>

<!--    提出-->
    <van-popup v-model:show="show" position="bottom" class="propose" closeable round>
      <div class="propose_title">
        {{$t('nft.tc')}}
      </div>
      <p>{{$t('nft.tcP')}}</p>
      <strong>{{walletAddress}}</strong>
      <van-button class="proposeBtn" @click=" extract(2)">{{$t('nft.qrtc')}}</van-button>
    </van-popup>
    <!--    转出-->
    <van-popup v-model:show="showTransfer" round position="bottom" closeable class="popup">
      <div class="transferShowTitle">{{$t('assetDetails.transfer')}}</div>
      <div class="transferContent">
        {{$t('assetDetails.num')}}：
        <span>{{balance}}</span>
      </div>
      <div class="transferContent">
        {{$t('assetDetails.balance')}}：
        <van-stepper v-model="value" theme="round" button-size="22" class="stepper"  :decimal-length="0" :disable-plus="value>=balance"/>
      </div>
      <div class="transferContent">
        {{$t('assetDetails.address')}}：
        <van-field v-model="address" :placeholder="$t('assetDetails.placeholder')"   rows="1"  type="textarea" autosize />
        <!--        <span>0x1029dadn1d19bd91d0b1db0d1b9d1</span>-->
      </div>
      <van-button class="gradient" @click=" sConfirmation(2)">{{$t('assetDetails.transferOut')}}</van-button>
    </van-popup>
    <!--   质押-->
    <van-popup v-model:show="reservationShow"   class="popupReservation">
      <div class="popupTitle">
        <div style="width: 24px;height: 15px"></div>
        <div class="title flex">
          {{type==1?$t('nft.zy'):$t('nft.tc')}}
        </div>
        <img src="../assets/close@2x.png" alt="" @click="reservationShow=false"/>
      </div>
      <div class="stepper">
        <van-stepper v-model="pledgeNum" theme="round"  :decimal-length="0"  button-size="27" :disable-plus="pledgeNum>=balance"/>
      </div>
      <div class="tcp" v-if="type==2">
        {{$t('nft.tcP')}}
        <div>{{walletAddress}}</div>
      </div>
      <van-button class="recharge" @click="submit">{{type==1?$t('nft.qrzy'):$t('nft.qrtc')}}</van-button>
    </van-popup>

<!--    二次确认-->
    <van-popup v-model:show="showSubmit"   class="popupReservation">
      <div class="popupTitle">
        <div style="width: 24px;height: 15px"></div>
        <div class="title flex">
          {{showSubmitType===1?$t('nft.qxzy'):$t('assetDetails.transfer')}}
        </div>
        <img src="../assets/close@2x.png" alt="" @click="showSubmit=false"/>
      </div>
      <div class="tcp" >
        <div style="font-size: 20px;margin-top: 15px">
          {{showSubmitType===1?$t('nft.qxzyP'):$t('nft.zcP')}}
        </div>
      </div>
      <van-button class="recharge"  @click="showSubmitType===1?extract(1):transferOut()" :disabled="disabled"> {{$t('nft.qd')}}</van-button>
    </van-popup>
  </div>

</template>

<script>
import Top from './components/Top'
import {getNftCardDetail, getBalance, handle, pledge, transOut, getNftCardDetailNoPledge, nftStatistics} from "@/api";
import {toHHmmss, div, add, sub} from "@/utils/decimalTool";
export default {
  name: "NFTDetails",
  components:{Top},

  data() {
    return {
      type:1,
      show:false,
      data:{
        proportion:0
      },
      address:'',
      value:1,
      balance:null,
      showTransfer:false,
      walletAddress:'',
      pledgeNum:1,
      reservationShow:false,
      showSubmit:false,
      showSubmitType:1,
      disabled:false
    }
  },
  created() {
    this.walletAddress=sessionStorage.getItem('walletAddress')
    this.httpInit()
  },
  methods: {
    getStatus(status){
      let text
      switch (status){
        case 1 :
          text=this.$t('nft.wzy')
          break
        case 2 :
          text=this.$t('nft.zyz')
          break
        case 5 :
          text=this.$t('nft.yfq')
          break
        case 6 :
          text=this.$t('nft.qyjs')
          break
      }
      return text
    },
    submit(){
      pledge({
        "pageNo": 0,
        "pageSize": 0,
        "data": {
          "type": this.type, //操作类型 1.质押 2.提出
          "count":this.pledgeNum //数量
        }
      }).then(()=>{
        if(this.type===1){
          this.$router.go(-1)
        }
        this.reservationShow=false
        this.$toast(this.$t('top.success'))
        this.httpInit()
      })
    },
    //质押
    storage(type){
      this.pledgeNum=1
      this.type=type
      this.reservationShow=true

    },
    transfer(){

      this.value=1
      this.address=null
      this.showTransfer=true
      this.httpInit()
    },
    sConfirmation(type){
      // 1 取消质押 2转出
      this.showSubmitType=type
      this.showSubmit=true

    },
    transferOut(){
      this.disabled=true
      transOut({
        "pageNo": 0,
        "pageSize": 0,
        "data": {
          "count": this.value, //转出数量
          "address": this.address //转出地址
        }
      }).then(res=>{
        this.showTransfer=false
        this.showSubmit=false
        setTimeout(()=>{
          this.disabled=false
        },1000)
        this.$toast(this.$t('top.success'))
        this.httpInit()
      })
    },
    extract(type){
      this.disabled=true
        handle({
          "pageNo": 0,
          "pageSize": 0,
          "data": {
            "id": this.$route.query.id, //id
            "type":type //操作类型 1.取消质押 2.提出
          }
        }).then(res=>{
          this.show=false
          this.showSubmit=false
          setTimeout(()=>{
            this.disabled=false
          },1000)
          this.$toast(this.$t('top.success'))
          this.httpInit()
        })

    },
    httpInit(){

      if(this.$route.query.status==1){
        nftStatistics().then(res=>{
          this.balance=res.data.noPledge
        })
        getNftCardDetailNoPledge().then(res=>{
          res.data.cardUrl=require('../assets/wzy.png')
          res.data.status=1
          res.data.creator='Seeshine'
          this.data=res.data
        })
      }else{
        getNftCardDetail(this.$route.query.id).then(res =>{
          let acquired  =sub(res.data.totalAmount,res.data.leftAmount)
          res.data.acquired=acquired
          res.data.proportion=div(acquired,res.data.totalAmount)*100
          res.data.pledgeTime=toHHmmss(res.data.pledgeTime,+8)

          this.data=res.data
        })
      }

    }
  }
}
</script>

<style scoped lang="less">
.NFTDetails{
  width: 100%;
  min-height: 100vh;
  background: linear-gradient(90deg, #6A2DD7 0%, #1C7FD6 50%, #224EAB 100%);
  padding-top: 61px;
  box-sizing: border-box;
  padding-bottom: 70px;
}
.picture{
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  .card{
    width: 241px;
    height: 297px;
    border-radius: 8px;
    margin-top: 25px;
    z-index: 1;
    object-fit:cover; //裁剪图片

  }
  .num{
    background: linear-gradient(90deg, #C72FF8 0%, #41CCF2 100%);
    border-radius: 14px;
    padding: 8px 19px 7px 19px;
    font-size: 16px;
    font-weight: bold;
    color: #FFFFFF;
    margin-top: -18px;
    z-index: 2;
  }
  .base{
    width: 293px;
    height: 80px;
    margin-top: -40px;
    z-index: 0;
    opacity: 0.3;
  }
}
.publisher{
  background: rgba(0,0,0,0.28);
  border: 1px solid rgba(255,255,255,0.28);
  border-radius: 4px;
  margin: 13px 28px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .publisherInformation{
    display: flex;

    img{
      width: 67px;
      height: 67px;
      border-radius: 4px;
      margin-right: 14px;
    }
   div{
     display: flex;
     flex-direction: column;
     align-items: start;
     p{
       font-size: 12px;
       font-weight: 500;
       color: #FFFFFF;
       margin: 10px 0;

     }
     span{
       font-size: 14px;
       font-weight: bold;
       color: #FFFFFF;
     }
   }
  }
  .status{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    height: 67px;
    margin-right: 11px;
    p{
      font-size: 14px;
      font-weight: bold;
      color: #F545F7;
      margin:  0;
    }
    span{
      font-size: 10px;
      font-weight: 500;
      color: #FFFFFF;
      margin-top: 9px;
    }
  }
}

.value p:nth-child(n){
text-align: left;
}
.value p:nth-child(2n){
  text-align: right;
}
.value p:last-child{
  text-align: center;
}
.value{
  width: 332px;
  background-color: rgba(18, 32, 119, 0.28);
  background-image: url("../assets/nft_bg.png");
  background-size: 144px 144px;
  background-repeat: no-repeat;
  background-position: 50%;
  border-radius: 4px;
  margin: 20px 28px;
  padding: 30px 25px 35px 25px;
  box-sizing: border-box;
  .nftValue{
    display: flex;
    align-items: center;
    justify-content: center;
    p{
      font-size: 14px;
      font-weight: 500;
      color: #FFFFFF;
      margin: 0;
      //padding-bottom: 30px;
      width: 50%;
      display: flex;
      flex-direction: column;
      align-items: center;
      span{
        font-size: 21px;
        font-weight: bold;
        color: #FFFFFF;
        margin-bottom: 5px;
      }
    }
  }

  .progress{
    width: 100%;
    margin-top: 35px;
    .progressValue{
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 15px;
      font-weight: bold;
      color: #2DDCF6;
      margin-bottom: 10px;
      span{
        font-size: 15px;
        font-weight: bold;
        color: #FFFFFF;
      }
    }
    .progressText{
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 12px;
      font-weight: 500;
      color: #D0E7F5;
      margin-top: 8px;
      span{

      }
    }
    /deep/.van-progress{

      border-radius: 7px;
    }
  }
}
.title{
  background-image: url("../assets/title_pic@2x.png");
  background-size: 100% 100%;
  min-width: 175px;
  padding: 7px 0;
  text-align: center;
  box-sizing: border-box;
  font-size: 16px;
  font-weight: bold;
  color: #0A1F4A;
  display: inline-block;

}
.story{
  background:rgba(18, 32, 119, 0.28);
  border-radius: 4px;
  margin: 11px 28px;
  padding: 23px 16px;
  display: flex;
  align-items: center;
  flex-direction: column;
  p{
    font-size: 15px;
    font-weight: 500;
    color: #FFFFFF;
    margin-top: 0;
    text-align: left;
  }
  .pic1{
    width: 80%;
    height: 313px;
    margin: 24px auto;
  }
  .pic2{
    width: 100%;
    height: 295px;
    margin: 20px auto;
  }
  .pic3{
    width: 70%;
    height: 210px;
    margin: 40px auto;
  }
  .pic4{
    width: 100%;
    height: 227px;
    margin: 20px auto;
  }
}
.btn{
  margin:23px 17px;
  display: flex;
  align-items: center;
  justify-content: space-between;

}
.gradient{
  width: 48%;
  height: 46px;
  background: linear-gradient(90deg, #C72FF8 0%, #41CCF2 100%);
  border-radius: 23px;
  font-size: 17px;
  font-weight: bold;
  color: #FFFFFF;
  border: none;
}
.blue{
  width: 48%;
  height: 46px;
  background: #2DDCF6;
  border-radius: 23px;
  border: none;
  font-size: 17px;
  font-weight: bold;
  color: #FFFFFF;
}
/deep/.propose{
  width: 390px;
  //height: 328px;
  background: #224EAB;
  padding-bottom: 35px;
  border-radius: 31px 31px 0px 0px;
  .propose_title{
    background: url("../assets/title_pic@2x.png");
    background-size: 100% 100%;
    min-width: 175px;
    padding:7px;
    text-align: center;
    display: inline-block;
    font-size: 16px;
    font-weight: bold;
    color: #0A1F4A;
  }
  p{
    font-size: 15px;
    font-weight: 500;
    color: #FFFFFF;
    margin: 39px 0;
  }
  strong{
    font-size: 22px;
    font-weight: 500;
    color: #FFFFFF;
    display: block;
    word-break: break-all;
    box-sizing: border-box;
    padding: 0 18px;
  }
  .proposeBtn{
    min-width: 312px;
    height: 46px;
    background: linear-gradient(90deg, #C72FF8 0%, #41CCF2 100%);
    border-radius: 23px;
    font-size: 17px;
    font-weight: bold;
    color: #FFFFFF;
    border: none;
    margin-top: 59px;
  }
}
/deep/.popup{
  background: #224EAB;
  border-radius: 31px 31px 0px 0px;
  padding: 21px 19px ;
  display: flex;
  align-items: center;
  flex-direction: column;
  box-sizing: border-box;
  padding-bottom: 50px;
  .transferShowTitle{
    width: 175px;
    background-image: url("../assets/title_pic@2x.png");
    background-size: 100% 100%;
    padding: 4px;
    font-size: 16px;
    font-weight: bold;
    font-style: italic;
    color: #0A1F4A;
    box-sizing: border-box;
    margin-bottom: 42px;
  }
  .transferContent{
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 16px;
    font-weight: 500;
    color: #FFFFFF;
    width: 100%;
    margin-bottom: 34px;
    //span{
    //  width: 211px;
    //  font-size: 16px;
    //  font-weight: 500;
    //  color: #FFFFFF;
    //  text-align: right;
    //  word-break: break-all;
    //}
    .van-cell{
      background: transparent;
      flex: 1;
    }

   .van-field__control{
      font-size: 16px;
      font-weight: 500;
      color: #FFFFFF;
      text-align: right;
      word-break: break-all;
    }
  }
  .stepper{
    width: 132px;
    background: #122077;
    border-radius: 10px;
    padding: 10px 12px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;

  .van-stepper__input{
      flex: 1;
      text-align: center;
      font-size: 18px ;
      font-weight: bold;
      color: #CCDBF1;
    }
    .van-stepper__plus{
      background: #2DDCF6 ;
      color: #122077;
      font-weight: bold;
    }
    .van-stepper__minus{
      background: #2DDCF6;
      color: #122077;
      font-weight: bold;
      border: none;
    }
  }

}
/deep/.popupReservation{
  width:75%;
  border-radius: 7px;
  background-color: #224EAB;
  background-image: url("../assets/soccer@2x.png");
  background-repeat: no-repeat;
  background-position: bottom right;
  background-size: 142px 183px;
  padding: 19px 15px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  .tcp{
    color: #fff;
    font-size: 14px;
    word-break: break-all;
    padding: 10px 20px 0 20px;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  div{
    font-weight: bold;
  }
  }
  .popupTitle{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    img{
      width: 15px;
      height: 15px;
    }
    .title{
      font-size: 16px;
      font-weight: bold;
      font-style: italic;
      color: #0A1F4A;
      background-image: url("../assets/title_pic@2x.png");
      background-size: 100% 100%;
      min-width: 150px;
      padding: 8px;
      justify-content: center;
    }

  }

  .recharge{
    width: 75%;
    height: 46px;
    background: linear-gradient(90deg, #C72FF8 0%, #41CCF2 100%);
    border-radius: 23px;
    border: 0;
    font-size: 17px;
    font-weight: 500;
    color: #FFFFFF;
    margin-top: 29px;
  }
 .stepper{
    background: #122077;
    border-radius: 10px;
    padding: 18px 22px ;
    width: 65%;
    margin-top: 29px;
    margin-bottom: 2px;
    .van-stepper--round{
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .van-stepper__input{
      font-size: 27px;
      font-weight: bold;
      color: #CCDBF1;
      flex: 1;
    }
    .van-stepper__plus{
      background: #2DDCF6;
      color: #122077;
      font-weight: bold;
    }
    .van-stepper__minus{
      background: #2DDCF6;
      color: #122077;
      font-weight: bold;
      border: none;
    }
  }
}
.bottom{
  width: 100%;
  box-sizing: border-box;
  position: fixed;
  bottom: 0;
  padding: 23px 17px ;
  background: linear-gradient(90deg, #6A2DD7 0%, #1C7FD6 50%, #224EAB 100%);
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 2;
}
</style>
