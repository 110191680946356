<template>
  <div class="transaction">
    <Top v-if="$route.query.type==1"></Top>
    <div class="top" v-else>
      <img src="../assets/back@2x.png" alt="" class="sidebar" @click="$router.go(-1)"/>
      <span>{{$route.meta.title}}</span>
      <span  class="record" @click="$router.push({path:'/records',query:{ assetsName:$route.query.assetsName}})">{{$t('transaction.record')}}</span>
    </div>
    <div v-if="$route.query.type!=1">
      <p class="title">
        {{$route.query.type==1?$t('transaction.zfdz'):$t('transaction.skdz')}}
      </p>
      <van-field v-model="address" clearable  :placeholder="$t('transaction.placeholder')" class="address"  v-if="$route.query.assetsName=='STT'"/>
      <div class="address" v-else>
        {{$route.query.type==1?data.address:data.realAddress}}
      </div>
    </div>

    <p class="title">
      {{$t('transaction.num')}}
      <span v-if="$route.query.type==2">{{data.balance}} {{$route.query.assetsName}}</span>
    </p>
    <div class="value">
      <van-field v-model="value"  :placeholder="$t('transaction.numP')"  @input="amountInput"/>
      {{$route.query.type==1?'USDT':''}}
    </div>

    <div class="tips"  v-if="$route.query.type==2" >
      <p>{{$t('transaction.tbsm')}}</p>
      <p>1、{{$t('transaction.tbsm1')}}</p>

    </div>

    <van-button class="btn" v-if="$route.query.type==1"  @click="next" :disabled="disabled">{{$t('transaction.next')}}</van-button>
    <van-button class="btn" v-else @click="submit" :disabled="disabled">{{$t('transaction.submit')}}</van-button>
<!--    提示-->
    <van-popup v-model:show="show"   class="popup " closeable>
      <div class="popupTitle">{{$t('transaction.tip')}}</div>
      <p>{{message}}</p>
    </van-popup>


  </div>

</template>

<script>
import Top from './components/Top'
import {getRechargeSdkInfo, ifExit, recharge, withdrawalHandle, withdrawalInfo} from "@/api";
import Web3 from "web3";
import {mul} from "@/utils/decimalTool";
import {Toast} from "vant";
import web3Modal from"@/utils/web3Modal"
export default {
  name: "transaction",
  components:{Top},
  mixins: [web3Modal],
  data() {
    return {
      value:null,
      show:false,
      data:{
        realAddress:''
      },
      disabled:false,
      message:'',
      address:''
    }
  },
  created() {
    // 1充币 2提币
    console.log(this.$route.query.type,)
    this.$route.meta.title=(this.$route.query.type==1?this.$t('assetDetails.recharge'):this.$t('assetDetails.with'))+'—'+ this.$route.query.assetsName
    if(this.$route.query.type==1){
      this.httpInit1()
    }else{
      this.httpInit2()
    }
  },
  methods: {
    async connect () {
      if(this.web3Provider==null) {

        const chainId = 56

        const customRpcs = Object.keys(this.chains).reduce(
            (rpcs, chainId) => {
              rpcs[chainId] = this.chains[chainId].rpc[0]
              return rpcs
            },
            {}
        )

        this.disabled = false
        this.$toast.clear()
        const _session = await this.provider.connect({
          namespaces: {
            eip155: {
              methods: [
                "eth_sendTransaction",
                "eth_signTransaction",
                "eth_sign",
                "personal_sign",
                "eth_signTypedData",
              ],
              chains: [`eip155:${chainId}`],
              events: ["chainChanged", "accountsChanged"],
              rpcMap: customRpcs,
            },
          },
        })

        this.session = _session
        await this.createWeb3Provider(this.provider)
        this.web3Modal?.closeModal()

        // await this.next()
      }
    },

    amountInput(value) {
      //只小数和整数
      this.value= value.replace(/[^\d.]/g,"").replace(/^\./g,"").replace(/\.{2,}/g,".").replace(".","$#$").replace(/\./g,"").replace("$#$",".")
      //this.amount=value.replace(/^\D*(\d*(?:\.\d{0,4})?).*$/g, '$1')"
      if (
          value.toString().split('.')[1] &&
          value.toString().split('.')[1].length >2
      ) {
        this.value = Number(value.substr(0, value.length - 1))
      }
    },
    submit(){
      let address
      if (this.$route.query.assetsName=='USDT') {
        address=this.data.realAddress
      } else {
        address=this.address
        const reg=/^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{3,}$///字母和数字且42位
        address =address?address.trim():address
        let sttAddressCheckPass = address && address.length > 3 && address.substring(0,3)==='stt' && reg.test(address)
        if(!sttAddressCheckPass){
          this.$toast(this.$t('transaction.placeholderAddress'))
          return
        }
      }

      // let address=this.$route.query.assetsName=='USDT'?this.data.realAddress:this.address

        if(Number(this.value)>0 ){
          this.disabled=true
          this.$toast({
            duration: 0,
            message: this.$t('home.loading'),
            icon: require('../assets/loading.gif'),
          });
          withdrawalHandle({
            "pageNo": 0,
            "pageSize": 0,
            "data": {
              "assetsName": this.$route.query.assetsName, //提现资产 传入当前页面资产所要提现得资产名称
              "amount": this.value, //提现金额
              address:address
            }
          }).then(res=>{
            this.$toast(res.message)
            this.disabled=false
            this.$toast.clear()
            this.$router.go(-1)
          })
              .catch(err=>{
                this.message=err.message
                this.show=true
                this.$toast.clear()

                this.disabled=false

              })
          this.$toast.clear()
          this.disabled=false
        }else{
          this.$toast(this.$t('transaction.placeholder'))
        }


    },
    async next(){
      if(Number(this.value)>0){
        this.$toast({
          duration: 2000,
          message: this.$t('home.loading'),
          icon: require('../assets/loading.gif'),
          overlay:true,
          forbidClick:true,
        });
        this.disabled=true
        let amount
        let fromAddress
        let ethContract
        if(window.statter){
            await this.connect()

          amount =mul(this.value,1*Math.pow(10,this.data.contractAddressScale)).toString();//转账100个
          fromAddress = await this.web3Provider.eth.getAccounts()
          ethContract =new this.web3Provider.eth.Contract(JSON.parse(this.data.abi), this.data.contractAddress, {
            from: fromAddress[0], // default from address
            gas: 30000,
            gasPrice: '20000000000',
            value:'10000000000'
          });
        }else{
          let web3 = new Web3(window.web3.currentProvider)
          //转账数量
           amount =mul(this.value,1*Math.pow(10,this.data.contractAddressScale))+'';//转账100个
           fromAddress = await web3.eth.getAccounts()
           ethContract =new web3.eth.Contract(JSON.parse(this.data.abi), this.data.contractAddress, {
            from: fromAddress[0], // default from address
             gas: 30000,
             gasPrice: '20000000000',
             value:'10000000000'
          });
        }

        // this.$toast({
        //   duration: 0,
        //   message:this.$t('home.loading'),
        //   icon: require('../assets/loading.gif'),
        // });
        //接收地址
        this.disabled=false

        // const result= ethContract.methods.transfer(this.data.address,amount).send({ from: fromAddress[0], gas:314159 })
        // console.log(result,'zhe')
        ethContract.methods.transfer(this.data.address,amount).send({ from: fromAddress[0], gas:314159})
            .then((res)=>{
              if(res.transactionHash){
                recharge({
                  "pageNo": 0,
                  "pageSize": 0,
                  "data": {
                    "hash": res.transactionHash, //hash
                    "rechargeNum": this.value //充值金额
                  }
                }).then(()=>{
                  this.$toast(this.$t('top.success'))
                  this.disabled=false
                  this.$toast.clear()
                  this.$router.go(-1)
                })
                .catch(err=>{
                  this.disabled=false
                  this.$toast.clear()
                })
              }else{
                console.log(res,'没有hash')
                this.disabled=false
                this.$toast.clear()
              }
            })
            .catch(err=>{
              this.$toast($t('top.error'))
              this.value=null
              this.disabled=false
              this.$toast.clear()
              console.log(err.message,'转账失败66666')
              // alert(err.message)
            })

        // this.$toast.clear()
        // this.disabled=false
      }else{
        this.$toast(this.$t('transaction.numP'))
      }



    },
    httpInit1(){
      getRechargeSdkInfo().then(res=>{
        this.data=res.data
      })
    },
    httpInit2(){
      console.log(this.$route.query.assetsName,'this.$route.query.assetsName')
      withdrawalInfo(this.$route.query.assetsName).then(res=>{
          this.data=res.data
      })
    }
  }
}
</script>

<style scoped lang="less">
.value{
  display: flex;
  align-items: center;
  width: 90%;
  font-size: 16px;
  font-weight: 500;
  color: #FFFFFF;
  border-bottom: 1px solid rgba(255,255,255,0.6);
  margin: 0 20px 30px 20px;
  padding: 15px 11px;
  box-sizing: border-box;
}
.transaction{
  width: 100%;
  height: 90vh;
  background: #122077;
  box-sizing: border-box;
  padding-top: 60px;
  .top{
    position: fixed;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 18px 20px 22px 20px;
    width: 100%;
    box-sizing: border-box;
    .sidebar{
      width: 16px;
      height: 11px;
      padding-right:30px;
    }
    span{
      font-size: 18px;
      font-weight: 500;
      color: #FFFFFF;
    }
    .logo{
      width: 21px;
      height: 18px;
    }
    .record{
      font-size: 16px;
      font-weight: 500;
      color: #2DDCF6;
    }
  }
  .title{
    text-align: left;
    padding: 0 18px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 15px;
    font-weight: 500;
    color: #FFFFFF;
  }
  .address{
    padding: 15px 11px;
    border-bottom: 1px solid rgba(255,255,255,0.6);
    margin: 0 20px 30px 20px;
    word-break:break-all;
    font-size: 14px;
    font-weight: 500;
    color: #2DDCF6;
    text-align: left;
      background: transparent;
      //margin: 0;
      //padding: 0;
      box-sizing: border-box;
      width: 90%;
    /deep/.van-field__control{
      font-size: 14px;
      font-weight: 500;
      color: #2DDCF6;
    }
  }
  /deep/.van-cell::after{
    display: none;

  }
  /deep/ input::placeholder{
    font-size: 16px;
    font-weight: 500;
    color: #81B4D1;
  }

  .value{
    /deep/.van-cell{
      background: transparent;
      margin: 0;
      padding: 0;
      box-sizing: border-box;
      width: 90%;

    }
    /deep/.van-field__control{
      font-size: 21px;
      font-weight: bold;
      color: #2DDCF6;
    }


  }

  .tips{
    background: #224EAB;
    border-radius: 5px;
    margin: 17px;
    padding: 17px 14px 14px 14px;
    font-size: 14px;
    font-weight: 500;
    color: #FBFBFB;
    p{
      text-align: left;
      margin-top: 0;
      margin-bottom: 5px;
    }
  }

}
.btn{
  position: absolute;
  bottom: 30px;
  left: 10%;
  width: 80%;
  height: 46px;

  background: linear-gradient(90deg, #C72FF8 0%, #41CCF2 100%);
  border-radius: 23px;
  border: 0;
  font-size: 17px;
  font-weight: bold;
  color: #FFFFFF;
}
/deep/.popup{
  width: 265px;
  min-height: 146px;
  background: #224EAB;
  border-radius: 10px;
  .popupTitle{
    font-size: 17px;
    font-weight: bold;
    color: #FFFFFF;
    margin-top: 22px;
  }
  p{
    font-size: 15px;
    font-weight: bold;
    color: #FFFFFF;
    margin-top: 32px;
  }
}
</style>
