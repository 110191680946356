<template>
  <div class="assets">
    <Top></Top>
    <van-pull-refresh v-model="isLoading" @refresh="onRefresh" pulling-text=" " loosing-text=" " loading-text=" " success-text=" " >
      <div class="top">
        <p>{{$t('assetDetails.zhzzc')}}(USDT)</p>
        <strong>{{data.totalBalance}}</strong>
      </div>
      <ul>
        <li v-for="(item,i) in data.list" :key="i" :style="{backgroundImage:'url('+item.background+')'}" @click="goDetails(item)">
          <div>
            <div class="name">
              <img :src="item.icon">
              {{item.currencyName}}
            </div>
            <p>{{item.balance}} <span>${{item.balanceTwo}}</span></p>
          </div>
          <img src="../assets/jt@2x.png" alt="" class="jt"/>
        </li>
      </ul>
    </van-pull-refresh>
  </div>

</template>

<script>
import Top from './components/Top'
import {Toast} from "vant";
import {getTotalAssets} from "@/api";
export default {
  name: "assets",
  components:{Top},
  data() {
    return {
      isLoading:false,
      data:{},
      currency:[
        {
          background:require('../assets/usdt_bg@2x.png'),
          icon:require('../assets/usdt@2x.png'),
          name:'USDT',
        },
        {
          background:require('../assets/stt_bg@2x.png'),
          icon:require('../assets/stt@2x.png'),
          name:'STT',
        },
        {
          background:require('../assets/nft_bg@2x.png'),
          icon:require('../assets/nft@2x.png'),
          name:'NFT',
        },
      ]
    }
  },
  created() {
    this.httpInit()
  },
  methods: {
    goDetails(item){
      this.$router.push({
        path:'/AssetDetails',
        query:{
          currencyName:item.currencyName
        }
      })
    },
    httpInit(){
      getTotalAssets().then(res=>{
        res.data.list.forEach(v=>{
          this.currency.forEach(e=>{
            if(e.name===v.currencyName){
              v=Object.assign(v, e);
              // v={...e,...v}
            }
          })
        })
        this.data=res.data
      })
    },
    onRefresh() {
      setTimeout(() => {
        Toast(this.$t('top.success'));

        // 判断是否登录
        if(sessionStorage.getItem('token')){
          this.httpInit()
        }else{
          this.$router.push('/')
        }
        this.isLoading = false;
      }, 1000);
    },

  }
}
</script>

<style scoped lang="less">
.assets{
  height: 100vh;
  width: 100%;
  background: url("../assets/zc_bg@2x.png") no-repeat;
  background-size: 100% 100%;
  .top{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    p{
      font-size: 15px;
      font-weight: 500;
      color: #FFFFFF;
      margin-top: 81px;
      margin-bottom: 31px;
    }
    strong{
      font-size: 37px;
      font-weight: bold;
      color: #FFFFFF;
    }
  }
  ul{
    margin: 17px;
    li{
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      background-size: 100% 100%;
      padding: 20px 24px 27px 32px;
      box-sizing: border-box;
      margin-bottom: 26px;
      .jt{
        width: 48px;
        height: 29px;
      }
      .name{
        font-size: 25px;
        font-weight: bold;
        color: #FFFFFF;
        display: flex;
        align-items: center;
        img{
          width: 42px;
          height: 42px;
          margin-right: 10px;
        }
      }
      p{
        font-size: 25px;
        font-weight: bold;
        color: #FFFFFF;
        margin-bottom: 0;
        margin-top: 20px;
        span{
          font-size: 16px;
          font-weight: bold;
          color: #FFFFFF;
        }
      }
    }
  }
}
</style>
