module.exports = {
    router:{
        home:'página de inicio',
        assets:'activos',
        invitation:'invitación',
        records:'Registro de retiro de moneda',
        NFTDetails:'NFT Detalles',
        NFTTabulation:'NFT Lista',
        announcement:'Anuncio',
        announcementDetails:'Detalles',
    },
    top:{
        connect:'Conexión',
        disconnect:'Desconexión',
        invcode:'código de invitación',
        invcode1:'Por favor, introduzca el Código de invitación',
        invcode2:'No hay invitados, salte este paso',
        success:'éxito',
        error:'Fracaso',

        tpTips:'por favor, elija el enlace de la billetera tp',
        yourCode:'su Código de invitación',
        fz:'copiar enlace',
        whz:'en mantenimiento del sistema',
        ztyh:'Usuarios de empuje directo',
        kc1: 'Piscina minera de una estrella',
        kc2: 'Piscina minera de dos estrellas',
        kc3: 'Piscina minera Samsung',
        cxs1: 'Asegurador primario',
        cxs2: 'Asegurador intermedio',
        cxs3: 'Asegurador Senior',
        cxs4: 'Proveedor de servicios',

    },
    home:{
        leadTime1:'Las instantáneas gratuitas están disponibles ',
        leadTime2:' minutos después de  compra grupal',
        noMore:'no más por el momento',
        loading:'en carga',
        days:'day',
        hours:'horas',
        minutis:'min',
        seconds:'segundos',
        sysl:"número de nft restantes",
        yyqg:"reserva para comprar",
        yyy:"reservado",
        tg:"compra grupal",
        qg:"compra",
        ysq:"agotado",
        yyts:"consejos de cita",
        gxn:"¡ felicitaciones, la cita fue exitosa!",
        hf:"el número de reservas que tiene que gastar al comprar",
        tqcz:"recargar con antelación puede tomar la delantera en la compra",
        xzcz:"recarga ahora",
        zbcz:"no recargar por el momento",
        buy:'compra',
        sx:'necesario',
        dqye:'saldo actual',
        yy:'cita',
        qryy:'confirme la cita',
        yebz:'saldo insuficiente',
        sl:'por favor, introduzca la cantidad',
    },
    assetDetails:{
        recharge:'Recargar',
        with:'Extracción',
        transfer:'transfer out',
        num:'número actual',
        balance:'número de transferencias',
        address:'objetos transferidos',
        transferOut:'confirme la transferencia',
        zr:'transferencia',
        zc:'transferir',
        placeholder:'dirección de destino',
        zhzzc:'equivalente a activos totales',
        qb:'todo',
        shz:'en revisión',
        yth:'devuelto',
        qkcl:'procesamiento de bloques',
        dqr:'por confirmar',
        txcg:'retiro de efectivo exitoso',
        txsb:'falló el retiro de efectivo',
        qrsb:'la confirmación falló',
        tqz:'En extracción'

    },
    transaction:{
        record:'registro',
        zfdz:'dirección de pago',
        skdz:'dirección del cobro',
        num:'cantidad',
        numP:'por favor, introduzca la cantidad',
        tbsm:'explicación de la moneda',
        tbsm1:'La tarifa de retiro es 1, que se deducirá del monto del retiro.',


        next:'siguiente paso',
        submit:'confirmar extracción',
        tip:'consejo',
        placeholder:'Por favor, introduzca el contenido',
        placeholderAddress:'Introduzca la dirección correcta',

    },
    nft:{
        cklb:'Ver lista',
        tokenIdP: 'por favor, introduzca nft tokenid',
        qx: 'all choice',
        zc: 'transferir',
        zy: 'pignoración',
        qb: 'todo',
        wzy: 'no prometido',
        zyz: 'en pignoración',
        tcz: 'en la propuesta',
        ytc: 'ya presentado',
        yfq: 'ha terminado',
        qyjs: 'fin del capital',
        fbz: 'editor',
        zysj: 'tiempo de pignoración',
        jz: 'nft valor',
        yhqqy:'derechos adquiridos',
        wk: 'minería stt',
        syqy: 'derechos e intereses residuales',
        llx: 'tipo de cadena',
        zpgs: 'historia de la obra',
        zpgst: 'maradona gana la Copa hércules',
        zpgsc: '¡ Maradona llevó a Argentina al título de la Copa del mundo de ese año en 1986, y este lanzamiento de nft es principalmente para conmemorarlo!',
        qxzy: 'cancelar la promesa',
        tcddz: 'proponer a la dirección',
        tc: 'proponer nft',
        tcP: 'proponer nft por defecto a la dirección de usuario actual',
        qrtc: 'confirme la presentación',
        qrzy: 'confirmación de la promesa',
        dq: 'nft actual',
        qy1: 'origen de la nft',
        qy2: 'en 1986, Maradona llevó al equipo argentino a la Copa del mundo de méxico, con la camiseta de la selección nacional, Brazaletes de capitán en los brazos, pendientes icónicos, forma de cabello rizado, objetivos, perseverancia en la luz y expresión de la confianza del rey. ',
        qy3: '¡ el prototipo de personaje nft se toma aquí!',
        zt: 'tema nft',
        zt1: 'los personajes cambian a través de diferentes pieles - diferentes colores de cabello y diferentes prendas de vestir',
        ys: 'elemento nft',
        ys1: 'basado en elementos de la tecnología del futuro, accesorios de personajes meta - cósmicos y pendientes de accesorios de ropa especiales, consta de pendientes de mezcla, cara, tocado y otras partes. ',
        bj: 'fondo nft',
        bj1: 'la clásica camiseta número 10, el letrero de maradona, la bandera argentina Hércules Cup Wings se mezcla con otros elementos en el rancho. ',
        zyP:'No hay nft pignorable',
        qxzyP:'Si se confirma la cancelación',
        zcP:'Si se confirma la transferencia',
        qd:'Determinar'

    }
}
