// 导入axios实例
import http from '@/utils/request.js'

// 注册登录
export function login(data) {
    return http({
        url: '/user/login',
        method: 'post',
        data:data
    })
}

// 判断是否注册
export function ifExit(data) {
    return http({
        url:`/user/ifExit?address=${data}`,
        method: 'get',
    })
}
// 获取用户信息
export function getUserInfo() {
    return http({
        url: '/user/getUserInfo',
        method: 'get',
    })
}
// 获取最新公告
export function getNewNotice() {
    return http({
        url: '/notice/getNotice',
        method: 'get',
    })
}
// 获取邀请码信息
export function getInviteInfo() {
    return http({
        url: '/user/getInviteInfo',
        method: 'get',
    })
}
// 资产总览
export function getTotalAssets() {
    return http({
        url: '/wallet/getTotalAssets',
        method: 'get',
    })
}
// 资产详情
export function getWalletDetail(data) {
    return http({
        url: '/wallet/getWalletDetail',
        method: 'post',
        data:data
    })
}
// 资产日志
export function getWalletLogList(data) {
    return http({
        url: '/wallet/getWalletLogList',
        method: 'post',
        data:data
    })
}
// 获取充值sdk需要信息
export function getRechargeSdkInfo() {
    return http({
        url: '/wallet/getRechargeSdkInfo',
        method: 'get',
    })
}
// 充值
export function recharge(data) {
    return http({
        url: '/wallet/recharge',
        method: 'post',
        data:data
    })
}
// 提币页面数据
export function withdrawalInfo(data) {
    return http({
        url: `/wallet/withdrawalInfo?assetsName=${data}`,
        method: 'get',
    })
}
// 提币
export function withdrawalHandle(data) {
    return http({
        url: '/wallet/withdrawalHandle',
        method: 'post',
        data:data
    })
}
// 提币记录
export function getWithdrawalList(data) {
    return http({
        url: '/wallet/getWithdrawalList',
        method: 'post',
        data:data
    })
}
// NFT 转出
export function transOut(data) {
    return http({
        url: '/wallet/transOut',
        method: 'post',
        data:data
    })
}
// 用户预约 (data 传预约数量)
export function reservation(data) {
    return http({
        url: '/home/reservation',
        method: 'post',
        data:data
    })
}
// 获取用户余额
export function getBalance(data) {
    return http({
        url: `/wallet/getBalance?assetsName=${data}`,
        method: 'get',
    })
}
// 用户购买NFT
export function purchase(data) {
    return http({
        url: '/home/purchase',
        method: 'post',
        data:data
    })
}
// 获取公告列表
export function getNoticeList(data) {
    return http({
        url: '/notice/getNoticeList',
        method: 'post',
        data:data
    })
}

// 获取最新详情
export function getNoticeDetail(data) {
    return http({
        url: `/notice/getNoticeDetail?id=${data}`,
        method: 'get',
    })
}
// 卡片列表
export function getNftCardList(data) {
    return http({
        url: '/nft/getNftCardList',
        method: 'post',
        data:data
    })
}
// NFT统计
export function nftStatistics() {
    return http({
        url: `/nft/nftStatistics`,
        method: 'get',
    })
}

// 卡片详情
export function getNftCardDetail(data) {
    return http({
        url: `/nft/getNftCardDetail?id=${data}`,
        method: 'get',
    })
}

// NFT 操作
export function handle(data) {
    return http({
        url: '/nft/handle',
        method: 'post',
        data:data
    })
}
// NFT 质押（data传质押数量）
export function pledge(data) {
    return http({
        url: '/nft/pledge',
        method: 'post',
        data:data
    })
}

// 未质押卡片详情
export function getNftCardDetailNoPledge() {
    return http({
        url: `/nft/getNftCardDetailNoPledge`,
        method: 'get',
    })
}
