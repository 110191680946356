module.exports = {
    router:{
        home: 'Home',
        assets: 'Assets',
        invitation: 'Invitation',
        records:'Cash withdrawal records',
        NFTDetails:'NFT Details',
        NFTTabulation:'NFT Tabulation',
        announcement:'Notice',
        announcementDetails:'Details',
    },
    top:{
        connect: 'Connect',
        disconnect: 'Disconnect',
        invcode: 'Invitation code',
        invcode1: 'Please enter the invitation code',
        invcode2: 'No inviters, skipping this step',
        success: 'Successful',
        error:'Error',

        tpTips: 'Please select the TP wallet link',
        yourCode: 'Your invitation code',
        fz: 'Copy Link',
        whz: 'System maintenance in progress',
        ztyh:'Direct Push Users',
        kc1: 'One star mining pool',
         kc2: 'Two Star Mine Pool',
        kc3: 'Three Star Mining Pool',
        cxs1: 'Junior underwriter',
        cxs2: 'Intermediate underwriter',
        cxs3: 'Senior Underwriter',
        cxs4: 'Service provider',

    },
    home:{
        leadTime1:'Free snap-ups are available ',
        leadTime2:' minutes after group',
        noMore: 'No more at the moment',
        loading: 'Loading',
        days: 'Days',
        hours: 'Hours',
        minutis:'minutes',
        seconds: 'second',
        sysl: "Remaining NFT quantity",
        yyqg: "Appointment for rush buying",
        yyy: "Reserved",
        tg: "Group buying",
        qg: "Rush to buy",
        ysq: "Sold out",
        yyts: "Appointment prompt",
        gxn: "Congratulations, the appointment was successful",
        hf: "The quantity you booked will need to be spent during the rush purchase",
        tqcz: "Recharging in advance is the only way to have an advantage in rush buying",
        xzcz: "Recharge now",
        zbcz: "No recharge temporarily",
        buy: 'Purchase',
        sx: 'Required',
        dqye: 'Current balance',
        yy: 'Appointment',
        qryy: 'Confirm appointment',
        yebz: 'Insufficient balance',
        sL: 'Please enter the quantity',
    },
    assetDetails:{
        recharge: 'Recharging',
        with: 'Extract',
        transfer: 'Transfer out',
        num: 'Current quantity',
        balance: 'Transfer out quantity',
        address: 'Transfer out object',
        transferOut: 'Confirm transfer out',
        zr: 'Transferred in',
        zc: 'Transfer out',
        placeholder: 'target address',
        zhzzc: 'Equivalent to total assets',
        qb: 'All',
        shz: 'Under review',
        yth: 'Returned',
        qkcl: 'Block processing',
        dqr: 'To be confirmed',
        txcg: 'Withdrawal successful',
        txsb: 'Withdrawal failed',
        qrsb: 'Confirmation failed',
        tqz:'Extracting'

    },
    transaction:{
        record: 'Record',
        zfdz: 'Payment address',
        skdz: 'Receiving address',
        num: 'Quantity',
        numP: 'Please enter the quantity',
        tbsm: 'Currency withdrawal description',
        tbsm1:'Withdrawal fee consumes 1.',
        next: 'Next',
        submit: 'Confirm extraction',
        tip: 'Prompt',
        placeholder:'Please enter content',
        placeholderAddress:'Please enter the correct address',

    },
    nft:{
        cklb:'view list',
        tokenIdP: 'Please enter NFT Token',
        qx: 'Select All',
        zc: 'Transfer out',
        zy: 'Pledge',
        qb: 'All',
        wzy: 'Not pledged',
        zyz: 'Under pledge',
        tcz: 'Proposed',
        ytc: 'Proposed',
        yfq: 'Ended',
        qyjs: 'End of equity',
        fbz: 'Publisher',
        zysj: 'Pledge time',
        jz: 'NFT value',
        yhqqy:'Acquired benefits',
        wk: 'Mining STT',
        syqy: 'Remaining equity',
        llx: 'Chain type',
        zpgs: 'Work Story',
        zpgsT: 'Maradona won the World Cup',
        zpgsC: 'Maradona led Argentina to win the World Cup championship in 1986, and the release of this NFT is mainly to commemorate him!',
        qxzy: 'Cancel pledge',
        tcddz: 'Propose to address',
        tc: 'Propose NFT',
        tcP: 'Propose NFT to default to the current user address',
        qrtc: 'Confirm proposal',
        qrzy: 'Confirm pledge',
        dq: 'Current NFT',
        qy1: 'Origin of NFT',
        qy2:' In 1986, Maradona led the Argentine team to participate in the Mexico World Cup, wearing the national team jersey, captain s armband on his arm, iconic earrings, curly hair design, goal, shining perseverance, and a confident expression from the king',
        qy3: 'The NFT character prototype is taken from here!',
        zt: 'NFT Theme',
        zt1: 'Characters change through different skin types - different hair colors and different clothing.',
        ys: 'NFT element',
        ys1: 'Based on the elements of future technology, Metaverse character accessories and special Fashion accessory earrings, it consists of mixed earrings, faces, headwear and other parts.',
        bj: 'NFT background',
        bj1: 'The classic No. 10 jersey, Maradona s signature Flag of Argentina, the Hercules Cup wings and other elements are mixed together.',
        zyP:'No Pledgable NFT',
        qxzyP:'Are you sure to cancel',
        zcP:'Are you sure to transfer out',
        qd:'Confirm'

    }
}
