const AnnouncementDetails = require("@/views/announcementDetails");
module.exports = {
    router:{
        home:'首页',
        assets:'资产',
        invitation:'邀请',
        records:'提币记录',
        NFTDetails:'NFT详情',
        NFTTabulation:'NFT列表',
        announcement:'公告',
        announcementDetails:'详情',
    },
    top:{
        connect:'链接',
        disconnect:'断开链接',
        invcode:'邀请码',
        invcode1:'请输入邀请码',
        invcode2:'没有邀请人，跳过此步骤',
        success:'成功',
        error:'失败',
        tpTips:'请选择TP钱包链接',
        yourCode:'您的邀请码',
        fz:'复制链接',
        whz:'系统维护中',
        ztyh:'直推用户',
        kc1:'一星矿池',
        kc2:'二星矿池',
        kc3:'三星矿池',
        cxs1:'初级承销商',
        cxs2:'中级承销商',
        cxs3:'高级承销商',
        cxs4:'服务商',

    },
    home:{
        leadTime1:'团购开启',
        leadTime2:'分钟后可自由抢购',
        noMore:'暂无更多',
        loading:'加载中',
        days:'天',
        hours:'小时',
        minutis:'分钟',
        seconds:'秒',
        sysl:"剩余NFT数量",
        yyqg:"预约抢购",
        yyy:"已预约",
        tg:"团购",
        qg:"抢购",
        ysq:"已售罄",
        yyts:"预约提示",
        gxn:"恭喜您，预约成功！",
        hf:"您预约的数量在抢购时需要花费",
        tqcz:"提前充值才能在抢购时占得先机哟",
        xzcz:"现在充值",
        zbcz:"暂不充值",
        buy:'购买',
        sx:'所需',
        dqye:'当前余额',
        yy:'预约',
        qryy:'确认预约',
        yebz:'余额不足',
        sl:'请输入数量',
    },
    assetDetails:{
        recharge:'充币',
        with:'提币',
        transfer:'转出',
        num:'当前数量',
        balance:'转出数量',
        address:'转出对象',
        transferOut:'确认转出',
        zr:'转入',
        zc:'转出',
        placeholder:'请输入地址',
        zhzzc:'折合总资产',
        qb:'全部',
        shz:'审核中',
        yth:'已退回',
        qkcl:'区块处理',
        dqr:'待确认',
        txcg:'提现成功',
        txsb:'提现失败',
        qrsb:'确认失败',
        tqz:'提取中'
    },
    transaction:{
        record:'记录',
        zfdz:'支付地址',
        skdz:'收款地址',
        num:'数量',
        numP:'请输入数量',
        tbsm:'提币说明',
        tbsm1:'提币手续费1个，从提现金额中扣除。',
        next:'下一步',
        submit:'确认提取',
        tip:'提示',
        placeholder:'请输入内容',
        placeholderAddress:'请输入正确的地址',
    },
    nft:{
        cklb:'查看列表',
        tokenIdP:'请输入NFT Tokenid',
        qx:'全选',
        zc:'转出',
        zy:'质押',
        qb:'全部',
        wzy:'未质押',
        zyz:'质押中',
        tcz:'提出中',
        ytc:'已提出',
        yfq:'已结束',
        qyjs:'权益结束',
        fbz:'发布者',
        zysj:'质押时间',
        jz:'NFT价值',
        yhqqy:'已获取权益',
        wk:'挖矿STT',
        syqy:'剩余权益',
        llx:'链类型',
        qxzy:'取消质押',
        tcddz:'提出到地址',
        tc:'提出NFT',
        tcP:'提出NFT默认提出至当前用户地址',
        qrtc:'确认提出',
        qrzy:'确认质押',
        dq:'当前NFT',
        qy1:'NFT起源',
        qy2:'1986年，马拉多纳率领阿根廷队参加了墨西哥世界杯的比赛，身穿国家队球衣，手臂上戴着队长袖标，佩戴着标志性的耳环，卷发造型，目标，光芒坚毅，充满了国王自信的表情。',
        qy3:'NFT角色原型取自这里！',
        zt:'NFT主题',
        zt1:'角色通过不同的皮肤变化-不同的头发颜色和不同的服装。',
        ys:'NFT元素',
        ys1:'基于未来技术、元宇宙人物配饰和特殊服装配饰耳环的元素，它由混搭耳环、面部、头饰以及其他部分。',
        bj:'NFT背景',
        bj1:'经典的10号球衣，马拉多纳的招牌阿根廷国旗大力神杯 翅膀和其他元素混合在一起拉奇。',
        zyP:'没有可质押NFT',
        qxzyP:'是否确认取消',
        zcP:'是否确认转出',
        qd:'确定'
    }
}
