import { createStore } from 'vuex'

export default createStore({
  state: {
    lange: '',
    web3Modal:null,
    provider:null,
    client:null,
    chains:null,
    web3Provider:null,
  },
  mutations: {
    setLang(state, lang) {
      state.lange = lang;
      sessionStorage.setItem('lange', lang)
    },
    setWeb3Modal(state, web3Modal) {
      state.web3Modal = web3Modal;
    },
    setProvider(state, provider) {
      state.provider = provider;
    },
    setClient(state, client) {
      state.client = client;
    },
    setChains(state, chains) {
      state.chains = chains;
    },
    setWeb3Provider(state, web3Provider) {
      state.web3Provider = web3Provider;
    },
    initLang(state) {
      if (sessionStorage.getItem('lange') == null) {
        state.lange = "en";
        sessionStorage.setItem('lange','en')
      } else {
        state.lange = sessionStorage.getItem('lange')
      }
    },
  },
  actions: {

  },
  modules: {
  }
})
