<template>
  <div>
    <Top ></Top>
    <van-pull-refresh v-model="isLoading" @refresh="onRefresh" pulling-text=" " loosing-text=" " loading-text=" " success-text=" " style="margin-top: 13%">
      <div class="home">
        <img src="../assets/banner@2x.png" alt="" class="banner"/>
        <van-notice-bar
            left-icon="volume"
            :text="notice"
            color="#fff"
            mode="link"
            @click="$router.push('/announcement')"
        />
<!--       -->

        <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white"  :width="300" :show-indicators="false" :lazy-rende="true">
          <van-swipe-item v-for="(item,i) in nftList" :key="i" >
            <img :src="item.img" alt="" />
          </van-swipe-item>
        </van-swipe>
        <div v-if="data.buttonShow==1 ||data.buttonShow==2">
          <div class="time" >
            <van-count-down :time="data.buyTime*1000" format="DD:HH:mm:ss" @finish="initWebSocket">
              <template #default="timeData">
                <span class="block" >{{ timeData.days }}</span>
                <span class="colon">:</span>
                <span class="block">{{ timeData.hours }}</span>
                <span class="colon">:</span>
                <span class="block">{{ timeData.minutes }}</span>
                <span class="colon">:</span>
                <span class="block" >{{ timeData.seconds }}</span>
              </template>
            </van-count-down>
            <p >
              <span>{{$t('home.days')}}</span>
              <span>{{$t('home.hours')}}</span>
              <span>{{$t('home.minutis')}}</span>
              <span>{{$t('home.seconds')}}</span>
            </p>
          </div>
          <div class="leadTime">（{{$t('home.leadTime1')+data.leadTime+$t('home.leadTime2')}}）</div>
        </div>

        <div class="num" v-else>
          <strong>{{data.nftCount}}</strong>
          <p>{{$t('home.sysl')}}</p>
        </div>
        <div v-if="loginStatus">
          <van-button class="pink" v-if="data.buttonShow==1" @click="value=0,reservationShow=true">{{$t('home.yyqg')}}</van-button>
          <van-button class="thorough" v-if="data.buttonShow==2" >{{$t('home.yyy')}}</van-button>
          <van-button class="gradient" v-if="data.buttonShow==3" @click="buyPopup(10)">{{$t('home.tg')}}</van-button>
          <van-button class="gradient" v-if="data.buttonShow==4" @click="buyPopup(1)" >{{$t('home.qg')}}</van-button>
          <van-button class="thorough" v-if="data.buttonShow==5" >{{$t('home.ysq')}}</van-button>
        </div>

      </div>
    </van-pull-refresh>

<!--    预约提示-->
    <van-popup v-model:show="rechargeShow"   class="popup ">
      <div class="popupTitle">
        <div style="width: 24px;height: 15px"></div>
        <div class="title flex">
          {{$t('home.yyts')}}
        </div>
        <img src="../assets/close@2x.png" alt="" @click="rechargeShow=false"/>
      </div>
      <p class="reservation1">{{$t('home.gxn')}}</p>
      <div class="num">
        <p>{{$t('home.hf')}}</p>
        <div>
          <strong>{{data.nftPrice * value}}</strong><span>USDT</span>
        </div>
      </div>
      <div class="tips">*{{$t('home.tqcz')}}！</div>
      <van-button class="recharge" @click="$router.push({
      path:'/transaction',
      query:{
        assetsName:'USDT',
        type:1
      }
      })">{{$t('home.xzcz')}}</van-button>
      <div  class="noRecharge" @click="rechargeShow=false,reservationShow=false">{{$t('home.zbcz')}}</div>
    </van-popup>

<!--    购买nft-->

    <van-popup v-model:show="buyShow"   class="popup " @close="value=1">
      <div class="popupTitle">
        <div style="width: 24px;height: 15px"></div>
        <div class="title flex">
          {{$t('home.buy')}}NFT
        </div>
        <img src="../assets/close@2x.png" alt="" @click="buyShow=false"/>
      </div>
      <div class="stepper">
        <van-stepper v-model="value" theme="round" button-size="27" disable-input :step="step" @change="needUsdt=data.nftPrice * value" :min="step"/>
      </div>
      <p class="balance">
        {{$t('home.sx')}}USDT：
        <span>{{needUsdt}}</span>
      </p>
      <p class="balance">
        {{$t('home.dqye')}}：
        <span>{{balance}}</span>
      </p>
      <van-button class="recharge" @click="panicBuying" :disabled="disabled">{{step==1?$t('home.qg'):$t('home.tg')}}</van-button>
    </van-popup>


<!--    预约nft-->
    <van-popup v-model:show="reservationShow"   class="popup">
      <div class="popupTitle">
        <div style="width: 24px;height: 15px"></div>
        <div class="title flex">
          {{$t('home.yy')}}NFT
        </div>
        <img src="../assets/close@2x.png" alt="" @click="reservationShow=false"/>
      </div>
      <div class="stepper">
        <van-stepper v-model="value" theme="round" button-size="27"/>
      </div>

      <van-button class="recharge" @click="submit">{{$t('home.qryy')}}</van-button>
    </van-popup>
  </div>

</template>

<script>
import {Toast} from "vant";

import {div, mul} from "@/utils/decimalTool";

import Top from './components/Top'
import {getBalance, getNewNotice, purchase, reservation} from "@/api";
import api from '../../base_config.json'
export default {
  name: 'Home',
  components:{Top},
   data(){
    return{
      loginStatus:false,
      webSock: null,
      lockReconnect: false, //避免重复连接
      notice:'',
      rechargeShow:false,
      buyShow:false,
      value:1,
      reservationShow:false,
      time: 30 * 60 * 60 * 1000,
      isLoading:false,
      nftList:[
        {img:require('@/assets/card.png')},
        {img:require('@/assets/card2.png')},
        {img:require('@/assets/card3.png')},
        {img:require('@/assets/card4.png')},
        {img:require('@/assets/card5.png')},
        {img:require('@/assets/card6.png')},
        {img:require('@/assets/card7.png')},
        {img:require('@/assets/card8.png')},
        {img:require('@/assets/card9.png')},
        {img:require('@/assets/card10.png')},
        {img:require('@/assets/card11.png')},
        {img:require('@/assets/card12.png')},
      ],
      data:{},
      step:1,
      needUsdt:0,
      balance:0,//余额
      disabled:false,
    }
   },
  created() {
    window.scrollTo(0,0)

    // 判断是否登录
    if(sessionStorage.getItem('token')){
      this.loginStatus=true
    }
    this.getNotice()
    this.initWebSocket()
  },

  beforeDestroy() {
    window.scrollTo(0,0)
  },
  destroyed() {
    // <!-- 离开页面时销毁socket服务 -->
    this.webSocketClose()
  },
  methods:{
    panicBuying(){
      this.disabled=true
      if( this.needUsdt>this.balance){
        this.$toast(this.$t('home.yebz'))
        this.disabled=false
      }else{
        purchase({
          "pageNo": 0,
          "pageSize": 0,
          "data": {
            "type": this.step==10?1:2, //类型 1.团购 2.抢购
            "count": this.value //数量
          }
        }).then(res=>{
          this.$toast(this.$t('top.success'))
          this.value=1
          this.buyShow=false
          setTimeout(()=>{
            this.disabled=false
          },1000)
        })
        .catch(()=>{
          setTimeout(()=>{
            this.disabled=false
          },1000)
        })
      }
    },
    buyPopup(num){
      getBalance('USDT').then(res=>{
        if(res.data.indexOf('.')!=-1){
          this.balance=res.data.slice(0, res.data.indexOf('.')+3)
        }else{
          this.balance=res.data
        }
        this.value=num
        this.step=num
        this.buyShow=true
        this.needUsdt= mul(this.value,this.data.nftPrice)
      })

    },
    // 确认预约
    submit(){
      if(this.value>0){
        reservation({
          "pageNo": 0,
          "pageSize": 0,
          "data": this.value
        }).then(res=>{
          this.value=1
          this.$toast(this.$t('top.success'))
          this.rechargeShow=true
        })
      }else{
        this.$toast(this.$t('home.sl'))
      }

    },
    // 发送websockwt请求
    initWebSocket() {
      // let websocketUrl = 'ws://192.168.5.17:8888/nt'
      let websocketUrl =api.socketApi+'/nt'
      // WebSocket与普通的请求所用协议有所不同，ws等同于http，wss等同于https
      this.webSock = new WebSocket(websocketUrl)
      this.webSock.onopen = this.webSocketOnOpen
      this.webSock.onerror = this.webSocketOnError
      this.webSock.onmessage = this.webSocketOnMessage
      this.webSock.onclose = this.webSocketClose
    },
    webSocketOnOpen() {
      console.log('WebSocket连接成功')
      //  传递参数  不需要传参就不传(参数根据后端需要的参数形式传递就行)
      let address = {
        method:'HOME_DATA',
        message:'',
        token:sessionStorage.getItem('token'),
        language:sessionStorage.getItem('lange')
      }
      this.webSocketSend(address)
    },
    webSocketOnMessage(e) {
      //接收数据
      // console.log('WebSocket 接受数据')
      // 将接收的数据转为json格式
      console.log('接受的数据',JSON.parse(e.data))
      var jsonObj = JSON.parse(e.data)



      this.data=jsonObj.data
      let time=div(this.data.leadTime,60)+''
      if(time.indexOf('.')!=-1){
        this.data.leadTime=time.slice(0,time.indexOf('.')+2)
      }else{
        this.data.leadTime=time

      }
      //根据socekt返回的数据判断需要哪个字段里面的数据(根据后端需要的数据判断即可)

    },
    webSocketClose(e) {
      console.log('断开连接')
      this.lockReconnect = false
      this.webSock.close()

      this.reconnect()
    },
    webSocketOnError(e) {
      console.log('报错信息', e)
    },
    webSocketSend(Data) {
      console.log(Data,'传的参数')
      //发送数据发送
      this.webSock.send(JSON.stringify(Data))
    },

    // 断开重连操作
    reconnect() {
      if (this.lockReconnect) return
      this.lockReconnect = true
      let _this = this
      //没连接上会一直重连，设置延迟避免请求过多
      setTimeout(function () {
        _this.initWebSocket()
        _this.lockReconnect = false
        // _this.isOne = 1;
      }, 2000)
    },

    getNotice(){
      getNewNotice().then(res=>{
        this.notice=res.data?.title
      })
    },
    onRefresh() {
      setTimeout(() => {
        this.$toast(this.$t('top.success'))

        // 判断是否登录
        if(sessionStorage.getItem('token')){
          this.loginStatus=true
        }
        this.getNotice()
        this.isLoading = false;
        this.finished=false
      }, 1000);
    },
    }
  }
</script>
<style lang="less" scoped>
.home{
  //min-height: 100vh;
  background: linear-gradient(-90deg, #6A2DD7, #1C7FD6, #224EAB);
  padding-bottom: 8%;
  .banner{
    margin: 17px;
    height: 156px;
  }
  .van-notice-bar{
    margin: 17px;
    margin-top: 0;
    background: rgba(18, 32, 119, 0.4);
    border: 1px solid rgba(45, 220, 246, 0.4);
    border-radius: 10px;
  }
  /deep/.van-icon-arrow:before{
    //content:'More';
    font-size: 12px;
    font-weight: 500;
    color: #FFFFFF;
    margin-left: 5px;
  }
}
.my-swipe{
  margin: 37px 0;
  img{
    width: 85%;
    height: 287px;
    margin: 0 20% ;
    object-fit:cover;
    border: 5px solid #2DDCF6;
    border-radius: 5px;
  }
  /deep/.van-swipe-item{
    height: 100%;

  }

}
.time{
  background-image: url("../assets/box@2x.png");
  background-size: 100% 100%;
  font-size: 15px;
  font-weight: 500;
  color: #FFFFFF;
  padding: 35px 43px;
  margin-top: 34px;
  margin-left: 17px;
  margin-right: 17px;

  .block{
    width: 20%;
    text-align: center;
  }
  /deep/.van-count-down{
    font-size: 37px;
    font-weight: 500;
    color: #FFFFFF;
    margin-bottom: 25px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    line-height: 27px;
  }
  p{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0;
    margin-top: 0;
    span{
      width: 20%;
      text-align: center;
    }
  }
}
.pink{
  width: 85%;
  margin: 18px 27px;
  height: 56px;
  background: #F545F7;
  border-radius: 28px;
  border: 0;
  font-size: 19px;
  font-weight: 500;
  color: #FFFFFF;
}
.thorough{
  width: 85%;
  margin: 18px 27px;
  height: 56px;
  background: rgba(255,255,255,0.3);
  border-radius: 28px;
  border: 0;
  font-size: 19px;
  font-weight: bold;

  color: #FFFFFF;
}
.gradient{
  width: 85%;
  margin: 18px 27px;
  height: 56px;
  background: linear-gradient(90deg, #C72FF8 0%, #41CCF2 100%);
  border-radius: 28px;
  border: 0;
  font-size: 19px;

  font-weight: bold;
  color: #FFFFFF;
}
.leadTime{
  font-size: 14px;
  font-weight: 500;
  color: #FFFFFF;
  text-align: center;
  margin-top: 12px;
  .van-count-down{
    display: inline-block;
    font-size: 14px;
    font-weight: 500;
    color: #FFFFFF;
  }
}
.num{
  margin: 17px;
  border: 3px solid transparent;
  border-radius: 31px;
  background-clip: padding-box, border-box;
  background-origin: padding-box, border-box;
  background-image: linear-gradient(90deg, #3989FF 0%, #7E24F9 100%), linear-gradient(0deg, #C72FF8, #41CCF2);
  padding: 35px ;
  display: flex;
  align-items: center;
  flex-direction: column;
  strong{
    font-size: 37px;
    font-weight: bold;
    color: #63D9FA;
    margin-bottom: 18px;
  }
  p{
    font-size: 15px;
    font-weight: normal;
    color: #FFFFFF;
    margin: 0;
  }
}
/deep/.popup{
  width:75%;
  //height: 239px;
  border-radius: 7px;
  background-color: #224EAB;
  background-image: url("../assets/soccer@2x.png");
  background-repeat: no-repeat;
  background-position: bottom right;
  background-size: 142px 183px;
  padding: 19px 15px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  .popupTitle{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    img{
      width: 15px;
      height: 15px;
    }
    .title{
      font-size: 16px;
      font-weight: bold;
      font-style: italic;
      color: #0A1F4A;
      background-image: url("../assets/title_pic@2x.png");
      background-size: 100% 100%;
      width: 175px;
      padding: 8px;
      justify-content: center;
    }

  }
  .reservation1{
    font-size: 19px;
    font-weight: bold;
    color: #F545F7;
    margin-top: 30px;
  }
  .num{
    background: #122077;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 20px;
    width: 75%;
    margin: 0;
    p{
      font-size: 15px;
      font-weight: 500;
      color: #FFFFFF;
    }
    strong{
      font-size: 25px;
      font-weight: bold;
      color: #2DDCF6;
    }
    span{
      font-size: 17px;
      font-weight: bold;
      color: #2DDCF6;
      margin-left: 3px;
    }
  }
  .tips{
    font-size: 14px;
    font-weight: 500;
    color: #2DDCF6;
    margin-top: 16px;
  }
  .recharge{
    width: 75%;
    height: 46px;
    background: linear-gradient(90deg, #C72FF8 0%, #41CCF2 100%);
    border-radius: 23px;
    border: 0;
    font-size: 17px;
    font-weight: 500;
    color: #FFFFFF;
    margin-top: 29px;
  }
  .noRecharge{
    font-size: 14px;
    font-weight: 500;
    color: #FFFFFF;
    margin-top: 15px;
  }
  .stepper{
    background: #122077;
    border-radius: 10px;
    padding: 18px 22px ;
    width: 65%;
    margin-top: 29px;
    margin-bottom: 2px;
    .van-stepper--round{
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .van-stepper__input{
      font-size: 27px;
      font-weight: bold;
      color: #CCDBF1;
      flex: 1;
    }
   .van-stepper__plus{
      background: #2DDCF6;
      color: #122077;
      font-weight: bold;
    }
    .van-stepper__minus{
      background: #2DDCF6;
      color: #122077;
      font-weight: bold;
      border: none;
    }
  }
.balance{
  font-size: 14px;
  font-weight: 500;
  color: #FFFFFF;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 78%;
  margin-top: 21px;
  margin-bottom: 0;
  span{
    font-size: 14px;
    font-weight: 500;
    color: #2DDCF6;
  }
}
}

</style>
