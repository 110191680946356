<template>
  <div class="records">
    <Top></Top>
    <van-tabs v-model:active="active" color="#2DDCF6" line-width="73" line-height="1" title-inactive-color="#81B4D1" title-active-color="#2DDCF6" @change="changeTab">
      <van-tab :title="item.title" v-for="(item,i) in typeList" :key="i" :name="item.name">
        <van-list
            v-model="loading"
            :finished="finished"
            :finished-text="$t('home.noMore')"
            :loading-text="$t('home.loading')"
            @load="onLoad"
        >
          <van-cell v-for="(item, i) in list" :key="i">
            <div class="list flex-between-center">
              <div class="left">
                <img src="../assets/clz@2x.png" alt=""/>
                <div>
                  <strong v-if="item.status">{{typeList[item.status].title}}</strong>
                  <span>{{item.addressS }}</span>
                  <span>{{item.createTime}}</span>
                </div>
              </div>
              <div class="right">
                <strong>{{item.initiateAmount}}</strong>
                <span>{{item.amount}}</span>
              </div>
            </div>
          </van-cell>
        </van-list>

      </van-tab>
    </van-tabs>
  </div>

</template>

<script>
import Top from './components/Top'
import {getWithdrawalList} from "@/api";
import {toHHmmss} from "@/utils/decimalTool";

export default {
  name: "records",
  components:{Top},

  data() {
    return {
      active:'',
      loading: false,
      finished: false,
      pageNo:1,
      list:[],
      total:0,
      typeList:[
        {title:this.$t('assetDetails.qb'), name:''},
        {title:this.$t('assetDetails.shz'), name:1},
        {title:this.$t('assetDetails.yth'), name:2},
        {title:this.$t('assetDetails.qkcl'), name:3},
        {title:this.$t('assetDetails.dqr'), name:4},
        {title:this.$t('assetDetails.txcg'), name:5},
        {title:this.$t('assetDetails.txsb'), name:6},
        {title:this.$t('assetDetails.qrsb'), name:7},
      ]
    }
  },
  created() {
    // console.log(this.$route.query.assetsName)
    // if(this.$route.query.assetsName=='STT'){
    //   this.typeList=[
    //     {title:this.$t('assetDetails.qb'), name:''},
    //     {title:this.$t('assetDetails.tqz'), name:1},
    //     {title:this.$t('assetDetails.txcg'), name:2},
    //     {title:this.$t('assetDetails.txsb'), name:3},
    //   ]
    // }
    this.httpInit()

  },
  methods: {
    changeTab(){
      this.list=[]
      this.pageNo=1
      this.finished=false
      console.log(this.active,'active9999')

      this.httpInit()
    },
    httpInit(){
        getWithdrawalList({
        "pageNo": this.pageNo,
        "pageSize": 10,
        "data": {
          'assetsName':this.$route.query.assetsName,
          "status": this.active, //状态 1.审核中  2.已退回  3.区块处理 4.待确认  5.提现成功 6.提现失败 7.确认失败
        }
      }).then(res=>{
            this.total=res.data.total
            res.data.list.forEach(v=> {
              v.createTime = toHHmmss(v.createTime , +8)
              v.addressS= v.address.substring(0, 9) +
                  '****' +
                  v.address.substr(v.address.length - 12)
              this.list.push(v)
            })
      })
    },
    onLoad() {
      setTimeout(() => {
        // 数据全部加载完成
        if (this.list.length >= this.total) {
          this.finished = true
        } else {
          this.pageNo += 1
          this.httpInit()
        }
        // 加载状态结束
        this.loading = false
      }, 1000)
    },
  }
}
</script>

<style scoped lang="less">
.records{
  width: 100%;
  min-height: 90vh;
  background: #122077;
  padding-top: 60px;
  box-sizing: border-box;
  /deep/.van-tabs__nav{
    background: transparent;
  }
  /deep/.van-cell{
    background: transparent;
    padding:  0;
  }
  /deep/.van-list{
    margin-top: 10px;
  }
  /deep/.van-cell::after{
    display: none;
  }
  .list{
    margin: 0  20px;
    padding: 20px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid rgba(255,255,255,0.4);
    .left{
      display: flex;
      align-items: center;
      div{
        display: flex;
        flex-direction: column;
      }
      img{
        width: 23px;
        height: 23px;
        margin-right: 12px;
        margin-left: 5px;
      }
      strong{
        font-size: 16px;
        font-weight: bold;
        color: #FFFFFF;
      }
      span{
        font-size: 12px;
        font-weight: 500;
        color: #FFFFFF;
        margin-bottom: -3px;
      }
    }
    .right{
      display: flex;
      flex-direction: column;
      text-align: right;
      strong{
        font-size: 16px;
        font-weight: bold;
        color: #fff;
      }
      span{
        font-size: 12px;
        font-weight: 500;
        color: #FFFFFF;
        margin-bottom: -3px;

      }
    }
  }

}
</style>
