<template>
  <div style="position: fixed;top:0;width: 100%;z-index: 5">
    <div class="Top" v-if="$route.path==='/'" >
      <div  class="flex">
        <img src="../../assets/list@2x.png" alt="" class="sidebar" @click="showList"/>
        <img src="../../assets/logo@2x.png" alt="" class="logo"/>
      </div>
      <div class="flex">
        <van-button class="btn" @click="logOn()" v-if="!loginStatus" :disabled="disabledLogin">{{$t('top.connect')}}</van-button>
        <span v-else>{{walletAddressYc}}</span>
        <van-dropdown-menu>
          <van-dropdown-item v-model="setLang" :options="option"     @change="changeLange" />
        </van-dropdown-menu>
      </div>
    </div>
    <div class="Top2" v-else>
      <img src="../../assets/back@2x.png" alt="" class="sidebar" @click="$router.go(-1)"/>
      <span>{{$t($route.meta.title)}}</span>
      <img src="../../assets/list2.png" alt="" class="logo" @click="show=true"/>
    </div>


    <van-popup v-model:show="showCode"   class="invitation " >
      <div class="invitationTop">
        <div style="width: 24px;height: 15px"></div>
        <div class="title flex">
          {{$t('top.invcode')}}
        </div>
        <img src="../../assets/close@2x.png" alt="" @click="showCode=false"/>
      </div>
      <p class="tips">{{$t('top.invcode1')}}</p>
      <van-field v-model="invitationCode" @input="updateCode" ref="invitationCode" maxlength="6"/>
      <van-button class="skip" @click="invitationCode='',logHttp()">{{$t('top.invcode2')}}</van-button>
    </van-popup>

    <van-popup v-model:show="show"  position="left" class="sidebarList">
      <img src="../../assets/back@2x.png" alt="" class="back" @click="show=false"/>
      <div class="userInfo" v-if="loginStatus">
        <img src="../../assets/user.png" alt="" />
        <div class="address">
          <strong>{{userInfo.nickname}}</strong>
          <p>{{walletAddressYc}}</p>
        </div>
      </div>
      <ul >
        <li @click="goBack('/')">
          <div>
            <img src="../../assets/home@2x.png" alt=""/>
           {{$t('router.home')}}
          </div>
          <img src="../../assets/arrow@2x.png" alt=""/>
        </li>
        <li @click="goBack('/assets')" v-if="loginStatus">
          <div>
            <img src="../../assets/zichan@2x.png" alt=""/>
            {{$t('router.assets')}}
          </div>
          <img src="../../assets/arrow@2x.png" alt=""/>
        </li>
        <li @click="goBack('/invitation')" v-if="loginStatus">
          <div >
            <img src="../../assets/yqm@2x.png" alt=""/>
            {{$t('router.invitation')}}
          </div>
          <img src="../../assets/arrow@2x.png" alt=""/>
        </li>
      </ul>
      <div class="break">
        <img src="../../assets/cbl_bg.png" alt=""/>
        <van-button class="disconnect" v-if="!loginStatus"  @click="logOn()" :disabled="disabledLogin">{{$t('top.connect')}}</van-button>
        <van-button class="disconnect" v-else @click="logOut">{{$t('top.disconnect')}}</van-button>
      </div>
    </van-popup>
  </div>

</template>

<script>
import {getUserInfo, ifExit, login} from "@/api";
import Web3 from "web3"
import web3Modal from"../../utils/web3Modal"
import Encrypt from "jsencrypt"
export default {
  name: "Top",
  mixins: [web3Modal],
  data() {
    return {
      invitationCode:'',
      loginStatus:false,
      walletAddressYc:'',
      account:'',
      showCode:false,
      show:false,
      setLang:'en',
      option: [
        // { text: '中文简体', value: 'zh' },
        { text: 'English', value: 'en' },
        { text: 'Español', value: 'es' },
        { text: '中文繁體', value: 'tc' },
      ],
      userInfo:{},
      dataToSign:'',
      signData:'',
      disabledLogin:false,
    }
  },

  created() {

    if(sessionStorage.getItem('lange')){
      this.setLang=sessionStorage.getItem('lange')
    }else{
      sessionStorage.setItem('lange', 'en')
      this.setLang='en'

      this.$store.commit('setLang','en')
    }
    if(sessionStorage.getItem('token')){
      this.loginStatus=true
      let wallStr=sessionStorage.getItem('walletAddress')
      this.walletAddressYc =
          wallStr.substring(0, 3) +
          '****' +
          wallStr.substr(wallStr.length - 4)
      this.getUser()
    }else{
      this.loginStatus=false
      this.getUrlKeyFromRequestUrl()
    }
  },

  methods: {
    showList(){
      this.show=true

    },
    async connect () {
      this.disabledLogin=true
      if (!this.provider) {
        throw new ReferenceError("WalletConnect Client is not initialized.")
      }

      const chainId =56
      // const chainId =838484

      if (!this.chains) {
        throw new ReferenceError("Chain NameSpace is undefined.")
      }
      this.disabledLogin=false

      const customRpcs = Object.keys(this.chains).reduce(
          (rpcs, chainId) => {
            rpcs[chainId] = this.chains[chainId].rpc[0]
            return rpcs
          },
          {}
      )

      const _session = await this.provider.connect({
        namespaces: {
          eip155: {
            methods: [
              "eth_sendTransaction",
              "eth_signTransaction",
              "eth_sign",
              "personal_sign",
              "eth_signTypedData",
            ],
            chains: [`eip155:${chainId}`],
            events: ["chainChanged", "accountsChanged"],
            rpcMap: customRpcs,
          },
        },
      })
      this.session = _session

      this.createWeb3Provider(this.provider)

      const _accounts = await this.provider.enable()
      console.log(_accounts,'_accounts')
      this.account = _accounts[0]
      this.web3Modal?.closeModal()
      this.disabledLogin=false
      this.walletAddress=_accounts[0]
      if (this.invitationCode != '') {
        this.logHttp()
      } else {
        ifExit(this.account).then(res => {
          if (res.data) {
            this.logHttp()
          } else {
            this.invitationCode = ''
            this.showCode = true
          }
        })
      }


    },


     getUrlKeyFromRequestUrl(){
       console.log(window.location.href)
      let str = window.location.href
      let index = str.indexOf('?inviteCode=')
      let strnew = str.slice(index + 12, index + 18)
      if (index != -1) {
        this.invitationCode = strnew
      } else {
        this.invitationCode = ''
      }
    },
    changeLange() {
      sessionStorage.setItem('lange', this.setLang)
      this.$i18n.locale = this.setLang
      this.$store.commit('setLang',this.setLang)
      this.$router.go(0)
    },

    goBack(url){
      if(this.$route.path!=url){
        this.$router.push(url)
      }else{
        this.show=false
      }
    },
    logOut(){
      this.loginStatus=false
      sessionStorage.removeItem('token')
      sessionStorage.removeItem('nickname')
      sessionStorage.removeItem('walletAddress')
        // this.web3Modal.closeModal()
      sessionStorage.removeItem('web3Modal')
      sessionStorage.removeItem('provider')
      sessionStorage.removeItem('client')
      sessionStorage.removeItem('chains')
      sessionStorage.removeItem('web3Provider')
      if(this.$route.path==='/'){
        this.$router.go(0)

      }else{
        this.$router.push('/')

      }

    },
    updateCode(){
      if(this.invitationCode.length===6){
        this.$refs.invitationCode.blur()
        this.logHttp()
      }
    },
    getUser(){
      getUserInfo().then(res=>{
        this.userInfo=res.data
      })
    },
    logHttp(){
      login({
        "pageNo": 0,
        "pageSize": 0,
        "data": {
          "address": this.account, //地址
          "invitationCode":this.invitationCode, //邀请码
          "sdkWalletType": 1, //sdk 钱包类型
          "chainStr": "BSC", //用户类型(链类型)  2.TRON 3.BSC
          signature:this.signature,//签名数据
          signData:this.dataToSign,//签名内容
        }
      }).then(res=>{
        this.$toast(this.$t('top.success'))
        this.showCode=false
        sessionStorage.setItem('token',res.data.token)
        sessionStorage.setItem('walletAddress',this.account)
        sessionStorage.setItem('nickname',res.data.nickname)
        this.loginStatus=true
        let wallStr=this.account.toString()
        this.walletAddressYc =
            wallStr.substring(0, 3) +
            '****' +
            wallStr.substr(wallStr.length - 4)
        this.getUser()
        this.$router.go(0)
      })
    },
     getEncrypt(data) {
      let encrypt = new Encrypt();
      encrypt.setPublicKey('MFwwDQYJKoZIhvcNAQEBBQADSwAwSAJBAMaLUBt5aikvYXNCugZaEfaZYgZl1eD1LgbYLPvzXqKKXL7IzDTedVi10g3rnQFQobfc0JWaaCj7Ll9OQCTRxHMCAwEAAQ==');
      return encrypt.encrypt(data)
    },
    async logOn(){
      const ETHEREUM = window.ethereum;
      const IS_TOKEN_POCKET = ETHEREUM && ETHEREUM.isTokenPocket;
      const CHAIN_ID = ETHEREUM && ETHEREUM.chainId;
      if(IS_TOKEN_POCKET && CHAIN_ID === '0x38' ){
       await this.tpLog()
      }else{
        this.$toast(this.$t('top.tpTips'))
      }

    },
    async tpLog(){
      //注册
      const accounts = await ethereum.request({
        method: 'eth_requestAccounts',
      })
      const account=accounts[0]
      let  web3 = new Web3(window.web3.currentProvider);
      const time=new Date().getTime()
      const auto=this.autoPassword()
      const dataToSign=time+'_'+auto
      await web3.eth.personal.sign(dataToSign, account, (error, hash) => {
        if (hash) {
          this.signature=hash
          this.dataToSign=this.getEncrypt(dataToSign)
          console.log(dataToSign,'dataToSign')
          console.log(account,'account')
          console.log(hash,'hash')
          if (this.invitationCode !== '') {
            this.account = account
            this.logHttp()
          } else {
            ifExit(account).then(res => {
              this.account = account
              if (res.data) {
                this.logHttp()
              } else {
                this.invitationCode = ''
                this.showCode = true
              }
            })
          }

        }else{
          this.dataToSign=''
          this.signData=''
        }
      })
    },
    // 随机生成8位字母+数字
     autoPassword() {
        //可获取的字符串
        const chars = 'ABCDEFGHIJKLMNOPQRSTUVWSYZabcdefghijklmnopqrstuvwsyz0123456789';
        const list = [];
        //通过随机获取八个字符串的索引下标
        for (let i = 0; i < 8; i++) {
          //61为chars字符串长度为62，注意索引是从0开始的
          const val_1 = Math.round(Math.random() * 61);
          list.push(val_1);
        }

        //再根据随机获取的8个字符串索引拿到这8个字符串进行拼接
        let passwd = '';
        for (let n = 0; n < list.length; n++) {
          passwd += chars.charAt(list[n]);
        }
        // var regNumber = /[A-Za-z0-9]{8}$/
        //最后判断是否符合要求（长度为8，由字母和数字组成），符合，将赋值到密码字段，不符合，重新调用该函数获取，只到符合为止
        const regNumber = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{8}$/;
        if (regNumber.test(passwd)) {
          return passwd;
        } else {
          return autoPassword();
        }
    }
  }
}
</script>

<style scoped lang="less">
.flex{
  display: flex;
  align-items: center;
}
.Top{
  background: #122077;
  height: 51px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 16px;
  span{
    font-size: 12px;
    font-weight: 500;
    color: #FFFFFF;
  }
  .sidebar{
    width: 21px;
    height: 17px;
    margin-right: 19px;

  }
  .logo{
    width: 106px;
    height: 32px;
  }
  .btn{
    display: inline-block;
    width: 83px;
    height: 31px;
    background: linear-gradient(90deg, #C72FF8 0%, #41CCF2 100%);
    border-radius: 16px;
    font-size: 15px;
    font-weight: 500;
    color: #FFFFFF;
    border: none;
    margin-right: 10px;
  }
  /deep/.van-dropdown-menu__bar{
    background: transparent;
    height: 51px;
    .van-dropdown-menu__title{
      font-size: 15px;
      font-weight: bold;
      color: #FFFFFF;
    }
  }
  /deep/.van-dropdown-item__option--active{
    color: #122077;
  }
  /deep/.van-dropdown-item__icon{
    color:#122077;
  }

}
.Top2{
  background: rgba(18,32,119,0.6);
  height: 51px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 16px;
  img:nth-child(1){
    width: 16px;
    height: 11px;
  }
  span{
    font-size: 18px;
    font-weight: 500;
    color: #FFFFFF;
  }
  img:nth-last-child(1){
    width: 21px;
    height: 18px;
  }
}
/deep/.sidebarList{
  height: 100vh;
  width: 75%;
  background: linear-gradient(-50deg, #6A2DD7 0%, #1C7FD6 50%, #224EAB 100%);
  //padding: 23px 19px 38px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  .back{
    width: 16px;
    height: 11px;
    padding-top: 23px;
    padding-left: 19px;
  }
  .userInfo{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 40px;
    margin-left: 27px;
    img{
      width: 53px;
      height: 53px;
      margin-right: 17px;
    }
    .address{
      display: flex;
      flex-direction: column;
      strong{
        font-size: 19px;
        font-weight: bold;
        color: #FFFFFF;
        display: inline-block;
      }
      p{
        font-size: 12px;
        font-weight: bold;
        color: #FFFFFF;
        text-align: left;
        margin: 5px 0 0 0 ;
      }
    }
  }
  ul{
    margin:46px 0 0 26px;
    width: 85%;
    li{
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 23px 0;

      img{
        width: 23px;
        height: 23px;
      }
      div{
        display: flex;
        align-items: center;
        img{
          margin-right: 20px;
        }
        font-size: 17px;
        font-weight: bold;
        color: #FAFAFA;
      }
    }
  }
  .break{
    width: 100%;
    height: 280px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    position: absolute;
    bottom: 0;
    img{
      width: 100%;
      height: 100%;
      opacity:0.8;

    }
    .disconnect{
      position: absolute;
      bottom: 40px;
      width: 208px;
      height: 46px;
      background: linear-gradient(90deg, #C72FF8 0%, #41CCF2 100%);
      border-radius: 23px;

      font-size: 17px;
      font-weight: bold;
      color: #FFFFFF;
      border: none;
    }
  }

}
/deep/.invitation{
  width:75%;
  //height: 239px;
  border-radius: 7px;
  background-color: #224EAB;
  background-image: url("../../assets/soccer@2x.png");
  background-repeat: no-repeat;
  background-position: bottom right;
  background-size: 142px 183px;
  padding: 19px 15px;
  .invitationTop{
    display: flex;
    align-items: center;
    justify-content: space-between;
    img{
      width: 15px;
      height: 15px;
    }
    .title{
      font-size: 16px;
      font-weight: bold;
      font-style: italic;
      color: #0A1F4A;
      background-image: url("../../assets/title_pic@2x.png");
      background-size: 100% 100%;
      width: 175px;
      height: 32px;
      justify-content: center;
    }

  }
  .tips{
    font-size: 15px;
    font-weight: bold;
    color: #CCDBF1;
    text-align: left  ;
    margin-top: 32px;
  }
  .van-cell{
    background: transparent;
    border-bottom: 1px solid #FFFFFF;

   /deep/ .van-field__control{
      font-size: 31px;
      font-weight: bold;
      color: #2DDCF6;
     //letter-spacing:20px;
    }
  }
  .skip{
    width: 187px;
    height: 32px;
    background: linear-gradient(90deg, #C72FF8 0%, #41CCF2 100%);
    border-radius: 16px;
    border: 0;
    margin-top: 28px;
    font-size: 12px;
    font-weight: bold;
    color: #FFFFFF;
  }
}
</style>
