module.exports = {
    router:{
        home:'首頁',
        assets:'資產',
        invitation:'邀請',
        records:'提幣記錄',
        NFTDetails:'NFT詳情',
        NFTTabulation:'NFT清單',
        announcement:'公告',
        announcementDetails:'詳情',
    },
    top:{
        connect:'連接',
        disconnect:'斷開連接',
        invcode:'邀請碼',
        invcode1:'請輸入邀請碼',
        invcode2:'沒有邀請人，跳過此步驟',
        success:'成功',
        error:'失敗',
        tpTips:'請選擇TP錢包連結',
        yourCode:'您的邀請碼',
        fz:'複製連結',
        whz:'系統維護中',
        ztyh:'直推用戶',
        kc1:'一星礦池',
        kc2:'二星礦池',
        kc3:'三星礦池',
        cxs1:'初級承銷商',
        cxs2:'中級承銷商',
        cxs3:'高級承銷商',
        cxs4:'服務商',

    }  ,
    home:{
        leadTime1:'團購開啟 ',
        leadTime2:' 分鐘後可自由搶購',
        noMore:'暫無更多',
        loading:'加載中',
        days:'天',
        hours:'小時',
        minutis:'分鐘',
        seconds:'秒',
        sysl:'剩餘NFT數量',
        yyqg:'預約搶購',
        yyy:'已預約',
        tg:'團購',
        qg:'搶購',
        ysq:'已售罄',
        yyts:'預約提示',
        gxn:'恭喜您，預約成功！',
        hf:'您預約的數量在搶購時需要花費',
        tqcz:'提前充值才能在搶購時占得先機喲',
        xzcz:'現在充值',
        zbcz:'暫不充值',
        buy:'購買',
        sx:'所需',
        dqye:'當前餘額',
        yy:'預約',
        qryy:'確認預約',
        yebz:'餘額不足',
        sl:'請輸入數量',
        },
    assetDetails:{
        recharge:'充幣',
        with:'提幣',
        transfer:'轉出',
        num:'當前數量',
        balance:'轉出數量',
        address:'轉出對象',
        transferOut:'確認轉出',
        zr:'轉入',
        zc:'轉出',
        placeholder:'請輸入地址',
        zhzzc:'折合總資產',
        qb:'全部',
        shz:'稽核中',
        yth:'已退回',
        qkcl:'區塊處理',
        dqr:'待確認',
        txcg:'提現成功',
        txsb:'提現失敗',
        qrsb:'確認失敗',
        tqz:'選取中'
    },
    transaction:{
        record:'記錄',
        zfdz:'支付地址',
        skdz:'收款地址',
        num:'數量',
        numP:'請輸入數量',
        tbsm:'提幣說明',
        tbsm1:'提幣手續費1個，從提現金額中扣除。',

        next:'下一步',
        submit:'確認選取',
        tip:'提示',
        placeholder:'請輸入內容',
        placeholderAddress:'請輸入正確的地址',

    },
    nft:{
        cklb:'查看清單',
        tokenIdP:'請輸入NFT Tokenid',
        qx:'全選',
        zc:'轉出',
        zy:'質押',
        qb:'全部',
        wzy:'未質押',
        zyz:'質押中',
        tcz:'提出中',
        ytc:'已提出',
        yfq:'已結束',
        qyjs:'權益結束',
        fbz:'發佈者',
        zysj:'質押時間',
        jz:'NFT價值',
        yhqqy:'已獲取權益',
        wk:'挖礦STT',
        syqy:'剩餘權益',
        llx:'鏈類型',
        zpgs:'作品故事',
        zpgsT:'馬拉多納獲得大力神杯',
        zpgsC:'馬拉多納在1986年帶領阿根廷獲得了當年的世界盃冠軍，這張NFT的發行主要是為了紀念他！',
        qxzy:'取消質押',
        tcddz:'提出到地址',
        tc:'提出NFT',
        tcP:'提出NFT默認提出至當前用戶地址',
        qrtc:'確認提出',
        qrzy:'確認質押',
        dq:'當前NFT',
        qy1:'NFT起源',
        qy2:'1986年，馬拉多納率領阿根廷隊參加了墨西哥世界盃的比賽，身穿國家隊球衣，手臂上戴著隊長袖標，佩戴著標誌性的耳環，卷髮造型，目標，光芒堅毅，充滿了國王自信的表情。',
        qy3:'NFT角色原型取自這裡！',
        zt:'NFT主題',
        zt1:'角色通過不同的皮膚變化-不同的頭髮顏色和不同的服裝。',
        ys:'NFT元素',
        ys1:'基於未來科技、元宇宙人物配飾和特殊服裝配飾耳環的元素，它由混搭耳環、面部、頭飾以及其他部分。',
        bj:'NFT背景',
        bj1:'經典的10號球衣，馬拉多納的招牌阿根廷國旗大力神杯翅膀和其他元素混合在一起拉奇。',
        zyP:'沒有可質押NFT',
        qxzyP:'是否確認取消',
        zcP:'是否確認轉出',
        qd:'確定'

    }
}
